import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Button } from '@mui/material';
import { Add as AddIcon, ArrowForward } from '@mui/icons-material';
import StepperPopup from '../../../../components/StepperPopup';
import StationForm from './StationForm';
import StationConfirmation from './StationConfirmation';
import LogoLoader from '../../../../components/LogoLoader';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
const Feedback = lazy(() => import('../../../../components/Feedback'));
const FailFeedback = lazy(
  () => import('../../../../components/DataTable/Feedback')
);

function CreateStation(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    props.sendData();
  };
  const [submit, setSubmit] = useState(0);
  const [payload, setPayload] = useState(null);
  const [canNext, setCanNext] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [error, response]);

  return (
    <>
      <LogoLoader loading={loading} />
      <Button variant="contained" color="primary" onClick={openModal}>
        <AddIcon />
        {t('LBLCreateCharginStations')}
      </Button>
      <StepperPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('LBLCreateCharginStations')}
        steps={[
          {
            label: t('EnterINfo'),
            component: (
              <StationForm
              locationIdInput={null}
                setSubmit={setSubmit}
                submit={submit}
                payload={payload}
                setPayload={setPayload}
                setCanNext={setCanNext}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prev) => prev + 1);
              },
              canNext: canNext,
            },
          },
          {
            label: t('confirmInfo'),
            component: (
              <StationConfirmation
                payload={payload}
                submit={confirmed}
                setError={setError}
                setResponse={setResponse}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              icon: <></>,
              action: () => {
                setConfirmed(true);
                setIsOpen(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <Suspense fallback={<div />}>
        <Feedback
          open={!!response}
          title={t('newCsDone')}
          message={
            <>
              <Typography align="center">{t('thankforInput')}</Typography>
              <Typography align="center">
                {t('newCssite')} <b>{response?.data?.data}</b> {t('beensetup')}.
                {t('teamwillbeintouch')}
              </Typography>
            </>
          }
          handleClose={() => {
            setResponse(null);
            setPayload(null);
            setConfirmed(false);
            onClose();
          }}
        />
        <FailFeedback
          open={!!error}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(null);
            setPayload(null);
            setConfirmed(false);
          }}
        />
      </Suspense>
    </>
  );
}

export default CreateStation;
