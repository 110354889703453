import React, { useEffect, useState, useMemo } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useFormik, Field, FormikProvider } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { Add as AddIcon } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components/macro';
import { Button as MuiButton } from '@mui/material';
import { spacing } from '@mui/system';
import { RadioGroup } from 'formik-mui';
import Radio from '@mui/material/Radio';
import { useTranslation } from 'react-i18next';
import { validators } from '../../../services/utils/validators'
const ButtonIcon = styled(MuiButton)(spacing);
const SmallButton = styled(ButtonIcon)`
  padding: 4px;
  min-width: 0;
  margin-top: 10px;
  color: grey;
  border: 1px solid grey;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;
function OperatorForm({ setPayload, submit, setCanNext, setSubmit }) {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalHeader, setTotalHeader] = useState(1);
  const [totalFooter, setTotalFooter] = useState(1);
  const [billingType, setBillingType] = useState('SEPA');

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        contactPersonName: yup.string().required(t('Namereq')),
        contactPersonPosition: yup.string().required(t('PositionReq')),
        contactPersonPhone: yup
          .number()
          .typeError(t('NumericOnly'))
          .required(t('TeleReq')),
        contactPersonEmail: yup
          .string()
          .matches(validators.EMAIL, t('wrongEmail'))
          .email(t('wrongEmail'))
          .required(t('emailReq')),
        billingType: yup.string().required(t('BillingReq')),
        moneyCollectionGapInDays: yup
          .number()
          .typeError(t('NumericOnly'))
          .required(t('reqField')),
        creditorId:
          billingType == 'BANK'
            ? yup.string()
            : yup.string().required(t('OpidReq')),
        cpoIban:
          billingType == 'BANK'
            ? yup.string().required(t('ibanReq')).matches(validators.IBAN,t('ibanValidation'))
            : yup.string(),
        cpoBankName:
          billingType == 'BANK'
            ? yup.string().required(t('opBankReq'))
            : yup.string(),
        cpoBic:
          billingType == 'BANK'
            ? yup.string().required(t('bicOpreq')).matches(validators.BIC,t('bicValidation'))
            : yup.string(),
        billingTemplateHeaderOne:
          totalHeader > 0 ? yup.string().required(t('h1req')) : yup.string(),
        billingTemplateHeaderTwo:
          totalHeader > 1 ? yup.string().required(t('h2req')) : yup.string(),
        billingTemplateHeaderThree:
          totalHeader > 2 ? yup.string().required(t('h3req')) : yup.string(),
        billingTemplateHeaderFour:
          totalHeader > 3 ? yup.string().required(t('h4req')) : yup.string(),
        billingTemplateFooterOne: yup.string(),
        billingTemplateFooterTwo: yup.string(),
        billingTemplateFooterThree: yup.string(),
        billingTemplateFooterFour: yup.string(),
        isChargingHistoryIncluded: yup.boolean(),
      }),
    [totalHeader, billingType]
  );
  useEffect(() => {
    if (submit > 0) document.getElementById('subErstellung').click();
    setSubmit(0);
  }, [submit]);
  const formik = useFormik({
    initialValues: {
      contactPersonName: '',
      contactPersonPosition: '',
      contactPersonPhone: '',
      contactPersonEmail: '',
      billingType: 'SEPA',
      moneyCollectionGapInDays: '',
      creditorId: '',
      cpoIban: '',
      cpoBankName: '',
      cpoBic: '',
      billingTemplateHeaderOne: '',
      billingTemplateHeaderTwo: '',
      billingTemplateHeaderThree: '',
      billingTemplateHeaderFour: '',
      billingTemplateFooterOne: '',
      billingTemplateFooterTwo: '',
      billingTemplateFooterThree: '',
      billingTemplateFooterFour: '',
      isChargingHistoryIncluded: true,
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setPayload({
        isChargingHistoryIncluded: values.isChargingHistoryIncluded,
        billingType: values.billingType,
        contactPersonDetails: {
          contactPersonName: values.contactPersonName,
          contactPersonPosition: values.contactPersonPosition,
          contactPersonPhone: values.contactPersonPhone,
          contactPersonEmail: values.contactPersonEmail,
        },
        cpoPaymentDetails: {
          cpoPaymentPreference: values.billingType,
          cpoIban: values.cpoIban,
          cpoBankName: values.cpoBankName,
          cpoBic: values.cpoBic,
          creditorId: values.creditorId,
          moneyCollectionGapInDays: values.moneyCollectionGapInDays,
        },
        billingTemplateHeader: {
          lineOne: values.billingTemplateHeaderOne,
          lineTwo: values.billingTemplateHeaderTwo,
          lineThree: values.billingTemplateHeaderThree,
          lineFour: values.billingTemplateHeaderFour,
        },
        billingTemplateFooter: {
          lineOne: values.billingTemplateFooterOne,
          lineTwo: values.billingTemplateFooterTwo,
          lineThree: values.billingTemplateFooterThree,
          lineFour: values.billingTemplateFooterFour,
        },
      });
    },
  });
  useEffect(() => {
    setCanNext(formik.isValid && formik.touched);
  }, [formik]);

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Typography
            fontWeight={700}
            variant="subtitle1"
            id="autorisierung-uber-label"
          >
            {t('cpInfo')}
          </Typography>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="contactPersonName"
                label="Name"
                variant="outlined"
                required
                fullWidth
                name={'contactPersonName'}
                my={2}
                value={formik.values.contactPersonName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonName &&
                  Boolean(formik.errors.contactPersonName)
                }
                helperText={
                  formik.touched.contactPersonName &&
                  formik.errors.contactPersonName
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="contactPersonPosition"
                label={t('job')}
                variant="outlined"
                required
                fullWidth
                name={'contactPersonPosition'}
                my={2}
                value={formik.values.contactPersonPosition}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonPosition &&
                  Boolean(formik.errors.contactPersonPosition)
                }
                helperText={
                  formik.touched.contactPersonPosition &&
                  formik.errors.contactPersonPosition
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="contactPersonPhone"
                label={t('PhoneNum')}
                variant="outlined"
                fullWidth
                required
                my={2}
                name={'contactPersonPhone'}
                value={formik.values.contactPersonPhone}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonPhone &&
                  Boolean(formik.errors.contactPersonPhone)
                }
                helperText={
                  formik.touched.contactPersonPhone &&
                  formik.errors.contactPersonPhone
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="contactPersonEmail"
                label="E-Mail"
                variant="outlined"
                required
                fullWidth
                my={2}
                name={'contactPersonEmail'}
                value={formik.values.contactPersonEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonEmail &&
                  Boolean(formik.errors.contactPersonEmail)
                }
                helperText={
                  formik.touched.contactPersonEmail &&
                  formik.errors.contactPersonEmail
                }
              />
            </Grid>
          </Grid>
          <br></br>
          <Typography
            fontWeight={700}
            variant="subtitle1"
            id="autorisierung-uber-label"
          >
            {billingType == 'BANK' ? (
              <div> {t('infoInvoice')}</div>
            ) : (
              <div>{t('InfoInv')}</div>
            )}
          </Typography>
          <br></br>
          <Grid container pacing={6}>
            <Grid item md={9}>
              <TextField
                margin="dense"
                id="billingTemplateHeaderOne"
                label={t('Hdr1')}
                variant="outlined"
                required={totalHeader > 0 ? true : false}
                fullWidth
                name={'billingTemplateHeaderOne'}
                my={2}
                value={formik.values.billingTemplateHeaderOne}
                onChange={formik.handleChange}
                error={
                  formik.touched.billingTemplateHeaderOne &&
                  Boolean(formik.errors.billingTemplateHeaderOne)
                }
                helperText={
                  formik.touched.billingTemplateHeaderOne &&
                  formik.errors.billingTemplateHeaderOne
                }
              />
            </Grid>
            {totalHeader == 1 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalHeader(2);
                  }}
                >
                  <AddIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader > 1 ? (
              <Grid item md={9}>
                <TextField
                  margin="dense"
                  id="billingTemplateHeaderTwo"
                  label={t('Hdr2')}
                  variant="outlined"
                  required={totalHeader > 1 ? true : false}
                  fullWidth
                  name={'billingTemplateHeaderTwo'}
                  my={2}
                  value={formik.values.billingTemplateHeaderTwo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingTemplateHeaderTwo &&
                    Boolean(formik.errors.billingTemplateHeaderTwo)
                  }
                  helperText={
                    formik.touched.billingTemplateHeaderTwo &&
                    formik.errors.billingTemplateHeaderTwo
                  }
                />
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader == 2 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalHeader(1);
                  }}
                >
                  <DeleteOutlineIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader == 2 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalHeader(3);
                  }}
                >
                  <AddIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader > 2 ? (
              <Grid item md={9}>
                <TextField
                  margin="dense"
                  id="billingTemplateHeaderThree"
                  label={t('Hdr3')}
                  variant="outlined"
                  required={totalHeader > 2 ? true : false}
                  fullWidth
                  name={'billingTemplateHeaderThree'}
                  my={2}
                  value={formik.values.billingTemplateHeaderThree}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingTemplateHeaderThree &&
                    Boolean(formik.errors.billingTemplateHeaderThree)
                  }
                  helperText={
                    formik.touched.billingTemplateHeaderThree &&
                    formik.errors.billingTemplateHeaderThree
                  }
                />
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader == 3 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalHeader(2);
                  }}
                >
                  <DeleteOutlineIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader == 3 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalHeader(4);
                  }}
                >
                  <AddIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader > 3 ? (
              <Grid item md={9}>
                <TextField
                  margin="dense"
                  id="billingTemplateHeaderFour"
                  label={t('Hdr1')}
                  variant="outlined"
                  required={totalHeader > 3 ? true : false}
                  fullWidth
                  name={'billingTemplateHeaderFour'}
                  my={2}
                  value={formik.values.billingTemplateHeaderFour}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingTemplateHeaderFour &&
                    Boolean(formik.errors.billingTemplateHeaderFour)
                  }
                  helperText={
                    formik.touched.billingTemplateHeaderFour &&
                    formik.errors.billingTemplateHeaderFour
                  }
                />
              </Grid>
            ) : (
              <div></div>
            )}
            {totalHeader > 3 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalHeader(3);
                  }}
                >
                  <DeleteOutlineIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>
          {/* footer starts here  */}
          <br></br>
          <Grid container pacing={6}>
            <Grid item md={9}>
              <TextField
                margin="dense"
                id="billingTemplateFooterOne"
                label={t('ftr1')}
                variant="outlined"
                fullWidth
                name={'billingTemplateFooterOne'}
                my={2}
                value={formik.values.billingTemplateFooterOne}
                onChange={formik.handleChange}
                error={
                  formik.touched.billingTemplateFooterOne &&
                  Boolean(formik.errors.billingTemplateFooterOne)
                }
                helperText={
                  formik.touched.billingTemplateFooterOne &&
                  formik.errors.billingTemplateFooterOne
                }
              />
            </Grid>
            {totalFooter == 1 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalFooter(2);
                  }}
                >
                  <AddIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter > 1 ? (
              <Grid item md={9}>
                <TextField
                  margin="dense"
                  id="billingTemplateFooterTwo"
                  label={t('ftr2')}
                  variant="outlined"
                  fullWidth
                  name={'billingTemplateFooterTwo'}
                  my={2}
                  value={formik.values.billingTemplateFooterTwo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingTemplateFooterTwo &&
                    Boolean(formik.errors.billingTemplateFooterTwo)
                  }
                  helperText={
                    formik.touched.billingTemplateFooterTwo &&
                    formik.errors.billingTemplateFooterTwo
                  }
                />
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter == 2 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalFooter(1);
                  }}
                >
                  <DeleteOutlineIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter == 2 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalFooter(3);
                  }}
                >
                  <AddIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter > 2 ? (
              <Grid item md={9}>
                <TextField
                  margin="dense"
                  id="billingTemplateFooterThree"
                  label={t('ftr3')}
                  variant="outlined"
                  fullWidth
                  name={'billingTemplateFooterThree'}
                  my={2}
                  value={formik.values.billingTemplateFooterThree}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingTemplateFooterThree &&
                    Boolean(formik.errors.billingTemplateFooterThree)
                  }
                  helperText={
                    formik.touched.billingTemplateFooterThree &&
                    formik.errors.billingTemplateFooterThree
                  }
                />
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter == 3 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalFooter(2);
                  }}
                >
                  <DeleteOutlineIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter == 3 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalFooter(4);
                  }}
                >
                  <AddIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter > 3 ? (
              <Grid item md={9}>
                <TextField
                  margin="dense"
                  id="billingTemplateFooterFour"
                  label={t('ftr4')}
                  variant="outlined"
                  fullWidth
                  name={'billingTemplateFooterFour'}
                  my={2}
                  value={formik.values.billingTemplateFooterFour}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingTemplateFooterFour &&
                    Boolean(formik.errors.billingTemplateFooterFour)
                  }
                  helperText={
                    formik.touched.billingTemplateFooterFour &&
                    formik.errors.billingTemplateFooterFour
                  }
                />
              </Grid>
            ) : (
              <div></div>
            )}
            {totalFooter > 3 ? (
              <Grid item marginLeft={2} md={1}>
                <SmallButton
                  size="medium"
                  mr={2}
                  onClick={() => {
                    setTotalFooter(3);
                  }}
                >
                  <DeleteOutlineIcon />
                </SmallButton>
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>

          <br></br>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <Typography
                fontWeight={700}
                variant="subtitle1"
                id="autorisierung-uber-label"
              >
                {t('paymenthow')}
              </Typography>
              <Field
                margin="none"
                sx={{ width: '100%' }}
                component={RadioGroup}
                aria-labelledby="abrechnungsart-label"
                name="billingType"
                value={billingType || ''}
                onBlur={formik.handleChange}
                onChange={(e) => {
                  setBillingType(e.target.value);
                }}
              >
                <FormControlLabel
                  value="SEPA"
                  sx={{ width: 'fit-content' }}
                  control={<Radio disabled={isSubmitting} />}
                  label={t('sepamode')}
                  disabled={isSubmitting}
                />
                <FormControlLabel
                  value="BANK"
                  sx={{ width: 'fit-content' }}
                  control={<Radio disabled={isSubmitting} />}
                  label={t('rechnung')}
                  disabled={isSubmitting}
                />
              </Field>
            </Grid>
            <Grid item md={6}>
              <Grid item md={12}>
                <Field
                  required
                  fullWidth
                  component={TextField}
                  id="moneyCollectionGapInDays"
                  label={t('Maturitytage')}
                  name="moneyCollectionGapInDays"
                  margin="none"
                  type="number"
                  value={formik.values.moneyCollectionGapInDays}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.moneyCollectionGapInDays &&
                    Boolean(formik.errors.moneyCollectionGapInDays)
                  }
                  helperText={
                    formik.touched.moneyCollectionGapInDays &&
                    formik.errors.moneyCollectionGapInDays
                  }
                  inputProps={{ min: 0, step: 1 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Tage</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {billingType == 'BANK' ? (
                <Grid item md={12}>
                  <TextField
                    margin="dense"
                    id="cpoBankName"
                    label={t('bankMode')}
                    variant="outlined"
                    required
                    fullWidth
                    name={'cpoBankName'}
                    my={2}
                    value={formik.values.cpoBankName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.cpoBankName &&
                      Boolean(formik.errors.cpoBankName)
                    }
                    helperText={
                      formik.touched.cpoBankName && formik.errors.cpoBankName
                    }
                  />
                </Grid>
              ) : (
                <Grid item md={12}>
                  <TextField
                    margin="dense"
                    id="creditorId"
                    label={t('opidlab')}
                    variant="outlined"
                    required
                    fullWidth
                    name={'creditorId'}
                    my={2}
                    value={formik.values.creditorId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.creditorId &&
                      Boolean(formik.errors.creditorId)
                    }
                    helperText={
                      formik.touched.creditorId && formik.errors.creditorId
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {billingType == 'BANK' ? (
            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  margin="dense"
                  id="cpoIban"
                  label={t('IbanOpr')}
                  variant="outlined"
                  required
                  fullWidth
                  name={'cpoIban'}
                  my={2}
                  value={formik.values.cpoIban}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cpoIban && Boolean(formik.errors.cpoIban)
                  }
                  helperText={formik.touched.cpoIban && formik.errors.cpoIban}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  margin="dense"
                  id="cpoBic"
                  label={t('BICOp')}
                  variant="outlined"
                  required
                  fullWidth
                  name={'cpoBic'}
                  my={2}
                  value={formik.values.cpoBic}
                  onChange={formik.handleChange}
                  error={formik.touched.cpoBic && Boolean(formik.errors.cpoBic)}
                  helperText={formik.touched.cpoBic && formik.errors.cpoBic}
                />
              </Grid>
            </Grid>
          ) : (
            <div></div>
          )}
          <Grid container spacing={6}>
            <Grid item md={6}>
              <Typography
                fontWeight={700}
                variant="subtitle1"
                id="autorisierung-uber-label"
              >
                {t('ProofLabel')}
              </Typography>
              <Field
                margin="none"
                sx={{ width: '100%' }}
                component={RadioGroup}
                aria-labelledby="abrechnungsart-label"
                name="isChargingHistoryIncluded"
                value={formik.values.isChargingHistoryIncluded}
                onChange={formik.handleChange}
              >
                <Grid item md={6}>
                  <FormControlLabel
                    value={false}
                    sx={{ width: 'auto' }}
                    control={<Radio disabled={isSubmitting} />}
                    label={t('no')}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControlLabel
                    value={true}
                    sx={{ width: 'auto' }}
                    control={<Radio disabled={isSubmitting} />}
                    label={t('yes')}
                    disabled={isSubmitting}
                  />
                </Grid>
              </Field>
            </Grid>
          </Grid>
          {/**
           * this button is hidden and used to trigger submit from outside of the component
           */}
          <Button
            sx={{ display: 'none' }}
            id="subErstellung"
            type="submit"
          ></Button>
        </form>
      </FormikProvider>
    </>
  );
}

export default OperatorForm;