/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../../Middleware/api/api';
import { useAuth, useUser } from '../../../services/contexts';
import { useTranslation } from 'react-i18next';
const initialValues = {
  cpoId: '',
  careOf: '',
  city: '',
  street: '',
  zipCode: '',
  locationName: '',
};

function LocationForm({ setPayload, submit, setCanNext, setSubmit }) {
  const { t } = useTranslation();
  const { token } = useAuth();
  const { user } = useUser();
  const [operators, setOperators] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [operatorsLoaded, setOperatorsLoaded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [operatorLoadError, setOperatorLoadError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [wallboxLoadError, setWallboxLoadError] = useState('');
  const [showEmsError, setShowEmsError] = useState(false);

  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click();
    setSubmit(0);
  }, [submit]);

  const getOperators = async () => {
    api.fetchOperators(token, 0,10000).then(
      (response) => {
        if (response.data.data == null) {
          setOperators([]);
        } else {
          setOperators(response.data.data.response);
        }
        setOperatorsLoaded(true);
      },
      (requestError) => {
        setOperatorLoadError(requestError.message);
      }
    );
  };
  useEffect(() => {
    getOperators();
  }, []);

  const validationSchema = useMemo(
    () =>
      yup.object({
        cpoId: yup.string().required(t('LBLOpIdreq')),
        careOf: yup.string(),
        city: yup.string().required(t('LBLLocReq')),
        street: yup.string().required(t('LBLNameReq')),
        zipCode: yup.string().required(t('ZipcodeReq')),
        locationName: yup.string().required(t('LNameReq')),
      }),
    []
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPayload({
        cpoId: values.cpoId,

        locationAddress: {
          careOf: values.careOf,
          city: values.city,
          street: values.street,
          zipCode: values.zipCode,
        },
        locationName: values.locationName,
        cpoName: operators.filter(
          (operator) => operator.cpoId === values.cpoId
        )[0]?.cpoName,
      });
    },
  });

  useEffect(() => {
    setCanNext(
      formik.isValid &&
        (formik.values.cpoId != '' ||
          formik.values.city != '' ||
          formik.values.street != '' ||
          formik.values.zipCode != '' ||
          formik.values.locationName != '')
    );
  }, [
    formik.isValid,
    formik.values.cpoId,
    formik.values.city,
    formik.values.street,
    formik.values.zipCode,
    formik.values.locationName,
  ]);

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Stack direction="column">
          <Grid container>
            <Grid item md={12} spacing={2}>
              <Typography variant="h4">{t('LBLPropInfo')}</Typography>
            </Grid>
            <Grid container md={12} spacing={2}>
              <Grid item xs>
                <TextField
                  margin="dense"
                  id="standortName"
                  label={t('Lname')}
                  name="locationName"
                  variant="outlined"
                  value={formik.values.locationName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.locationName &&
                    Boolean(formik.errors.locationName)
                  }
                  helperText={
                    formik.touched.locationName && formik.errors.locationName
                  }
                  required
                  fullWidth
                  my={2}
                />
              </Grid>
              <Grid item xs></Grid>
            </Grid>
            <Grid container md={12} spacing={2}>
              <Grid item xs>
                <TextField
                  margin="dense"
                  id="locationStreetAndNumber"
                  label={t('lblStName')}
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  error={formik.touched.street && Boolean(formik.errors.street)}
                  helperText={formik.touched.street && formik.errors.street}
                  variant="outlined"
                  required
                  fullWidth
                  my={2}
                  name="street"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  margin="dense"
                  id="flatNr"
                  value={formik.values.careOf}
                  onChange={formik.handleChange}
                  error={formik.touched.careOf && Boolean(formik.errors.careOf)}
                  helperText={formik.touched.careOf && formik.errors.careOf}
                  label="c/o"
                  variant="outlined"
                  fullWidth
                  my={2}
                  name="careOf"
                />
              </Grid>
            </Grid>
            <Grid container md={12} spacing={2}>
              <Grid item xs>
                <TextField
                  margin="dense"
                  id="zipCode"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.zipCode && Boolean(formik.errors.zipCode)
                  }
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                  label={t('PostalCode')}
                  variant="outlined"
                  required
                  fullWidth
                  my={2}
                  name="zipCode"
                />
              </Grid>
              <Grid item xs>
                <TextField
                  margin="dense"
                  id="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  label={t('city')}
                  variant="outlined"
                  required
                  fullWidth
                  my={2}
                  name="city"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} spacing={2} sx={{ pt: 2 }}>
              <Typography variant="h4">{t('MenuOp')}</Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <TextField
                  select
                  fullWidth
                  label={t('OpIdName')}
                  value={formik.values.cpoId}
                  onChange={formik.handleChange}
                  error={formik.touched.cpoId && Boolean(formik.errors.cpoId)}
                  helperText={
                    (formik.touched.cpoId && formik.errors.cpoId) ||
                    (operatorsLoaded && operators.length === 0)
                      ? t('LBLOpIdreq')
                      : ''
                  }
                  name="cpoId"
                >
                  {operators.map((operator, index) => (
                    <MenuItem key={index} value={operator.cpoId}>
                      {operator.cpoId + ': ' + operator.cpoName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </Grid>
        </Stack>
        <Button
          sx={{ display: 'none' }}
          id="sub"
          onClick={() => setShowEmsError(true)}
          type="submit"
        ></Button>
      </form>
    </>
  );
}

export default LocationForm;