/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import api from '../../../Middleware/api/api';
import { useAuth } from '../../../services/contexts';
import { setSession } from '../../../services/utils/jwt';
import { useTranslation } from 'react-i18next';
OperatorConfirmation.propTypes = {};
function OperatorConfirmation({ payload, submit, setSuccess, setError }) {
  const { t } = useTranslation();
  const { token, refreshToken, origin } = useAuth();
  useEffect(() => {
    if (submit) {
      api.createNewOperator(token, payload).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          api
            .refreshTokenRequest(token, {
              currentAccessToken: token,
              currentRefreshToken: refreshToken,
              origin: origin,
            })
            .then((tokenResponse) => {
              setSession(
                tokenResponse.data.data.access_token,
                tokenResponse.data.data.refresh_token
              );
              setSuccess(tokenResponse.data.data.access_token);
            });
        },
        (requestError) => {
          setError(true);
        }
      );
    }
  }, [submit]);

  return (
    <>
      <Grid container spacing={6} sx={{ marginY: 5 }}>
        <Grid item md={6}>
          {t('opName')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload.cpoName}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {t('LBLAddress')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload.cpoAddress?.careOf
            ? 'c/o ' + payload.cpoAddress?.careOf
            : ''}
          {payload.cpoAddress?.careOf ? <br /> : ''}
          {payload.cpoAddress?.street} <br />
          {payload.cpoAddress?.zipCode} {payload.cpoAddress?.city}
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {t('cpInfo')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload.contactPersonDetails?.contactPersonName}
          <br />
          {payload.contactPersonDetails?.contactPersonPosition
            ? payload.contactPersonDetails?.contactPersonPosition
            : ''}
          <br />
          {payload.contactPersonDetails?.contactPersonPhone
            ? payload.contactPersonDetails?.contactPersonPhone
            : ''}
          <br />
          {payload.contactPersonDetails?.contactPersonEmail
            ? payload.contactPersonDetails?.contactPersonEmail
            : ''}
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {t('invoiceInfo')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload.billingTemplateHeader?.lineOne != ''
            ? '1. ' + payload.billingTemplateHeader?.lineOne
            : ''}
          {payload.billingTemplateHeader?.lineOne != '' ? <br /> : ''}
          {payload.billingTemplateHeader?.lineTwo != ''
            ? '2. ' + payload.billingTemplateHeader?.lineTwo
            : ''}
          {payload.billingTemplateHeader?.lineTwo != '' ? <br /> : ''}
          {payload.billingTemplateHeader?.lineThree != ''
            ? '3. ' + payload.billingTemplateHeader?.lineThree
            : ''}
          {payload.billingTemplateHeader?.lineThree != '' ? <br /> : ''}
          {payload.billingTemplateHeader?.lineFour != ''
            ? '4. ' + payload.billingTemplateHeader?.lineFour
            : ''}
          {payload.billingTemplateHeader?.lineFour != '' ? <br /> : ''}
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {payload.billingTemplateFooter?.lineOne != '' ? (
            <div>{t('invoiceFooter')}</div>
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload.billingTemplateFooter?.lineOne != ''
            ? '1. ' + payload.billingTemplateFooter?.lineOne
            : ''}
          {payload.billingTemplateFooter?.lineOne != '' ? <br /> : ''}
          {payload.billingTemplateFooter?.lineTwo != ''
            ? '2. ' + payload.billingTemplateFooter?.lineTwo
            : ''}
          {payload.billingTemplateFooter?.lineTwo != '' ? <br /> : ''}
          {payload.billingTemplateFooter?.lineThree != ''
            ? '3. ' + payload.billingTemplateFooter?.lineThree
            : ''}
          {payload.billingTemplateFooter?.lineThree != '' ? <br /> : ''}
          {payload.billingTemplateFooter?.lineFour != ''
            ? '4. ' + payload.billingTemplateFooter?.lineFour
            : ''}
          {payload.billingTemplateFooter?.lineFour != '' ? <br /> : ''}
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {t('payrecepmode')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload?.billingType != ''
            ? payload.billingType == 'BANK'
              ? t('rechnung')
              : t('sepacoll')
            : ''}
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {t('Maturitytage')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload?.cpoPaymentDetails?.moneyCollectionGapInDays != ''
            ? payload?.cpoPaymentDetails?.moneyCollectionGapInDays + t('tages')
            : ''}
        </Grid>
      </Grid>
      <br></br>
      {payload?.billingType == 'BANK' ? (
        <Grid container spacing={1}>
          <Grid item md={6}>
            {t('IbanOpr')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload?.cpoPaymentDetails?.cpoIban != ''
              ? payload?.cpoPaymentDetails?.cpoIban
              : ''}
          </Grid>
          <Grid item md={6}>
            {t('bankMode')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload?.cpoPaymentDetails?.cpoBankName != ''
              ? payload?.cpoPaymentDetails?.cpoBankName
              : ''}
          </Grid>
          <Grid item md={6}>
            {t('BICOp')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload?.cpoPaymentDetails?.cpoBic != ''
              ? payload?.cpoPaymentDetails?.cpoBic
              : ''}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          <Grid item md={6}>
            {t('CreditInfo')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload?.cpoPaymentDetails?.creditorId != ''
              ? payload?.cpoPaymentDetails?.creditorId
              : ''}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default OperatorConfirmation;