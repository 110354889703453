/* eslint-disable no-extra-boolean-cast */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import api from '../../../Middleware/api/api';
import { useAuth } from '../../../services/contexts';
import { setSession } from '../../../services/utils/jwt';

import { useTranslation } from 'react-i18next';
OperatorConfirmation.propTypes = {};
function OperatorConfirmation({ payload, submit, setSuccess, setError }) {
  const { t } = useTranslation();
  const { token, refreshToken, origin } = useAuth();
  useEffect(() => {
    if (submit) {
      if (payload.cpoAddress && !payload.cpoAddress.careOf) {
        delete payload.cpoAddress.careOf;
      }
      if (payload.billingType) {
        delete payload.billingType;
      }
      if (payload?.cpoPaymentDetails?.cpoPaymentPreference != null && payload?.cpoPaymentDetails?.cpoPaymentPreference != '') {
        if (payload?.cpoPaymentDetails?.cpoPaymentPreference.includes('BANK_TRANSFER') && payload?.cpoPaymentDetails?.cpoPaymentPreference.includes('SEPA')) {
          payload.cpoPaymentDetails.cpoPaymentPreference = ["SEPA", "BANK_TRANSFER"]
        } else if (!payload?.cpoPaymentDetails?.cpoPaymentPreference.includes('BANK_TRANSFER')) {
          payload.cpoPaymentDetails.cpoPaymentPreference = ["SEPA"]
        }
        else if (!payload?.cpoPaymentDetails?.cpoPaymentPreference.includes('SEPA')) {
          payload.cpoPaymentDetails.cpoPaymentPreference = ["BANK_TRANSFER"]
        }
      }
      api.createNewOperator(token, payload).then(
        // eslint-disable-next-line no-unused-vars
        (response) => {
          api
            .refreshTokenRequest(token, {
              currentAccessToken: token,
              currentRefreshToken: refreshToken,
              origin: origin,
            })
            .then((tokenResponse) => {
              setSession(
                tokenResponse.data.data.access_token,
                tokenResponse.data.data.refresh_token
              );
              setSuccess(tokenResponse.data.data.access_token, response);
            });
        },
        // eslint-disable-next-line no-unused-vars
        (requestError) => {
          setError(true);
        }
      );
    }
  }, [submit]);

  return (
    <>
      <Grid container spacing={6} sx={{ marginY: 5 }}>
        <Grid item md={6}>
          {t('opName')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload.cpoName}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {t('LBLAddress')}
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {!!payload.cpoAddress?.careOf
            ? 'c/o ' + payload.cpoAddress?.careOf
            : ''}
          {!!payload.cpoAddress?.careOf ? <br /> : ''}
          {payload.cpoAddress?.street} <br />
          {payload.cpoAddress?.zipCode} {payload.cpoAddress?.city}
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={1}>
        <Grid item md={6}>
          {t('cpInfo')} 
        </Grid>
        <Grid item md={6} sx={{ textAlign: 'right' }}>
          {payload.contactPersonDetails?.contactPersonName}
          <br />
          {!!payload.contactPersonDetails?.contactPersonPosition
            ? payload.contactPersonDetails?.contactPersonPosition
            : ''}
          <br />
          {!!payload.contactPersonDetails?.contactPersonPhone
            ? payload.contactPersonDetails?.contactPersonPhone
            : ''}
          <br />
          {!!payload.contactPersonDetails?.contactPersonEmail
            ? payload.contactPersonDetails?.contactPersonEmail
            : ''}
        </Grid>
      </Grid>
      <br></br>
      {payload?.billingTemplateHeader && (
        <Grid container spacing={1}>
          <Grid item md={6}>
            {t('invoiceInfo')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload.billingTemplateHeader?.lineOne != ''
              ? '1. ' + payload.billingTemplateHeader?.lineOne
              : ''}
            {payload.billingTemplateHeader?.lineOne != '' ? <br /> : ''}
            {payload.billingTemplateHeader?.lineTwo != ''
              ? '2. ' + payload.billingTemplateHeader?.lineTwo
              : ''}
            {payload.billingTemplateHeader?.lineTwo != '' ? <br /> : ''}
            {payload.billingTemplateHeader?.lineThree != ''
              ? '3. ' + payload.billingTemplateHeader?.lineThree
              : ''}
            {payload.billingTemplateHeader?.lineThree != '' ? <br /> : ''}
            {payload.billingTemplateHeader?.lineFour != ''
              ? '4. ' + payload.billingTemplateHeader?.lineFour
              : ''}
            {payload.billingTemplateHeader?.lineFour != '' ? <br /> : ''}
          </Grid>
        </Grid>
      )}

      <br></br>
      {payload?.billingTemplateFooter && (
        <Grid container spacing={1}>
          <Grid item md={6}>
            {payload.billingTemplateFooter?.lineOne != '' ? (
              <div>{t('invoiceFooter')}</div>
            ) : (
              <div></div>
            )}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload.billingTemplateFooter?.lineOne != ''
              ? '1. ' + payload.billingTemplateFooter?.lineOne
              : ''}
            {payload.billingTemplateFooter?.lineOne != '' ? <br /> : ''}
            {payload.billingTemplateFooter?.lineTwo != ''
              ? '2. ' + payload.billingTemplateFooter?.lineTwo
              : ''}
            {payload.billingTemplateFooter?.lineTwo != '' ? <br /> : ''}
            {payload.billingTemplateFooter?.lineThree != ''
              ? '3. ' + payload.billingTemplateFooter?.lineThree
              : ''}
            {payload.billingTemplateFooter?.lineThree != '' ? <br /> : ''}
            {payload.billingTemplateFooter?.lineFour != ''
              ? '4. ' + payload.billingTemplateFooter?.lineFour
              : ''}
            {payload.billingTemplateFooter?.lineFour != '' ? <br /> : ''}
          </Grid>
        </Grid>
      )}

      <br></br>
      {payload?.billingType && (
        <Grid container spacing={1}>
          <Grid item md={6}>
            {t('payrecepmode')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload?.billingType != '' &&
              payload.billingType?.includes('BANK_TRANSFER')  &&
              !payload.billingType?.includes('SEPA')
              ? <div>{t('rechnung')}</div>
              :   !payload.billingType?.includes('BANK_TRANSFER')  &&
              payload.billingType?.includes('SEPA')
              ? <div>{t('sepamode')}</div> :  <div>{t('sepamode')}, {t('rechnung')}</div>} 
          

          </Grid>
        </Grid>
      )}

      <br></br>
      {payload?.cpoPaymentDetails && (
        <Grid container spacing={1}>
          <Grid item md={6}>
            {t('noOfDaysUntillPayment')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload?.cpoPaymentDetails?.moneyCollectionGapInDays != ''
              ? payload?.cpoPaymentDetails?.moneyCollectionGapInDays +
              ' ' +
              t('tages')
              : ''}
          </Grid>
        </Grid>
      )}

      <br></br>
      {payload?.billingType && (
        <>
          {payload?.billingType?.includes('BANK_TRANSFER') ? (
            <Grid container spacing={1}>
              <Grid item md={6}>
                {t('iban')}
              </Grid>
              <Grid item md={6} sx={{ textAlign: 'right' }}>
                {payload?.cpoPaymentDetails?.cpoIban != ''
                  ? payload?.cpoPaymentDetails?.cpoIban
                  : ''}
              </Grid>
              <Grid item md={6}>
                {t('bankMode')}
              </Grid>
              <Grid item md={6} sx={{ textAlign: 'right' }}>
                {payload?.cpoPaymentDetails?.cpoBankName != ''
                  ? payload?.cpoPaymentDetails?.cpoBankName
                  : ''}
              </Grid>
              <Grid item md={6}>
                {t('bic')}
              </Grid>
              <Grid item md={6} sx={{ textAlign: 'right' }}>
                {payload?.cpoPaymentDetails?.cpoBic != ''
                  ? payload?.cpoPaymentDetails?.cpoBic
                  : ''}
              </Grid>
            </Grid>
          ) : null}

          {payload?.billingType?.includes('SEPA') ?
            (
              <Grid container spacing={1}>
                <Grid item md={6}>
                  {t('CreditInfo')}
                </Grid>
                <Grid item md={6} sx={{ textAlign: 'right' }}>
                  {payload?.cpoPaymentDetails?.creditorId != ''
                    ? payload?.cpoPaymentDetails?.creditorId
                    : ''}
                </Grid>
              </Grid>
            ) : null}
        </>
      )}

      <br></br>
      {payload?.isChargingHistoryIncluded && (
        <Grid container spacing={1}>
          <Grid item md={6}>
            {t('proofIndiv')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {payload?.isChargingHistoryIncluded == true ? t('yes') : t('no')}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default OperatorConfirmation;