import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TableCell, Typography, Grid, Menu, MenuItem } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import DataTable from '../../../components/DataTable';
import styled from 'styled-components/macro';
import MuiPaper from '@mui/material/Paper';
import { spacing } from '@mui/system';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import StepperPopup from '../../../components/StepperPopup';
import UpdateOperatorERP from './UpdateOperatorERP';
import ConfirmOperatorERP from './ConfirmOperatorERP';
import { ArrowForward } from '@mui/icons-material';
import Feedback from '../../../components/Feedback';
import Feedback_Error from '../../../components/DataTable/Feedback';
import LogoLoader from '../../../components/LogoLoader';
const Paper = styled(MuiPaper)(spacing);
// eslint-disable-next-line no-unused-vars
const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4"], &[data-colindex="5"]':
              {
                '& > *': {
                  display: 'flex',
                  justifyContent: 'right',
                  textAlign: 'right',
                },
                '& .MuiButton-root': {
                  margin: 'auto',
                },
              },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4"], &[data-colindex="5"]':
              {
                '& > *': {
                  justifyContent: 'right',
                  textAlign: 'right',
                },
                '& .MuiButton-root': {
                  margin: 'auto',
                },
              },
          },
        },
      },
    },
  });

export function ErpValuesListView({ operatorInfo, setRefreshTheData }) {
  const { t } = useTranslation();
  const [anchorIconButton, setAnchorIconButton] = useState(null);
  const [openElem, setOpenElem] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateERPForm, setUpdateERPForm] = useState(false);
  const [step, setStep] = useState(0);
  const handleNext = () => setStep(step + 1);
  const [canNext, setCanNext] = useState(false);
  const [submit, setSubmit] = useState(0);
  const [payload, setPayload] = useState(null);
  const [confirmed, setConfirmed] = useState(0);
  const [error, setError] = useState(false);
  const [completedERP, setCompletedERP] = useState(false);
  const openFeedbackERP = () => setCompletedERP(true);
  const [loading, setLoading] = useState(false);

  const handleClickIconMenu = (elem) => (event) => {
    setAnchorIconButton(event.currentTarget);
    setOpenElem(elem);
    setSelectedRow(elem);
    setUpdateERPForm(true);
  };
  const handleCloseIconMenu = () => {
    setOpenElem(null);
    setAnchorIconButton(null);
    setUpdateERPForm(false);
  };
  const handleSetPayload = (input) => {
    setPayload(input);
  };
  const closeFeedback = () => {
    setCompletedERP(false);
    setPayload(null);
    setRefreshTheData(true);
    setError(false);
    setUpdateERPForm(false);
  };

  useEffect(() => {
    setLoading(false);
  }, [completedERP, error]);

  const columns = useMemo(
    () => [
      {
        name: 'cpoId',
        label: t('LBLOpId'),
        options: {
          filter: false,
          display: false,
          customBodyRender: (value) => (
            <Typography align="left">{value}</Typography>
          ),
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
            >
              {t('LBLOpId')}
            </TableCell>
          ),
        },
      },
      {
        name: 'clientNumber',
        label: t('clientNo'),
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => (
            <Typography align="left">{value}</Typography>
          ),
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
            >
              {t('clientNo')}
            </TableCell>
          ),
        },
      },
      {
        name: 'netLedgerAccountNr',
        label: t('glNum'),
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => (
            <Typography align="left">{value}</Typography>
          ),
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
            >
              {t('glNum')}
            </TableCell>
          ),
        },
      },
      {
        name: 'taxLedgerAccountNr',
        label: t('glNumVat'),
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => (
            <Typography align="left">{value}</Typography>
          ),
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
            >
              {t('glNumVat')}
            </TableCell>
          ),
        },
      },
      {
        name: 'paymentMethod',
        key: 'paymentMethod',
        label: t('LBLpaymentMethod'),
        options: {
          filter: false,
          customHeadRender: () => (
            <TableCell
              align="left"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLpaymentMethod')}
            </TableCell>
          ),
          customBodyRender: (paymentMethod) => {
            return (
              <>
                <Typography align="left">
                  {paymentMethod === 'BANK_TRANSFER'
                    ? t('rechnung')
                    : paymentMethod === 'STRIPE_SEPA'
                      ? t('sepaStripemode')
                      : paymentMethod === 'SEPA'
                        ? t('LBLsepamode')
                        : paymentMethod}
                </Typography>
              </>
            );
          },
        },
      },
      {
        name: 'edit',
        label: ' ',
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <IconButton onClick={handleClickIconMenu(value)}>
                    <EditIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorIconButton}
                    key={value}
                    open={openElem === value}
                    onClose={handleCloseIconMenu}
                    MenuListProps={{
                      'aria-labelledby': 'edit-selected',
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem
                      key={value}
                      onClick={() => {
                        handleCloseIconMenu();
                      }}
                    >
                      <Typography align="center" sx={{ width: '100%' }}>
                        {t('LBLParkplatzhinterlegen')}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            );
          },
        },
      },
    ],
    [operatorInfo]
  );

  const renderData = useMemo(() => {
    const ErpValues = operatorInfo?.erpValues;

    return ErpValues?.map((entry) => {
      const { ...ErpValues } = entry;

      const newEntry = {
        ...ErpValues,
        edit: entry,
        details: entry,
      };

      return newEntry;
    });
  }, [operatorInfo]);

  const options = {
    pagination: false,
    filter: false,
    search: false,
    textLabels: {
      body: {
        noMatch: t('LBLNoData'),
      },
    },
    selectableRows: 'none',
  };
  return (
    <>
      <LogoLoader loading={loading} />
      <Paper>
        <>
          <ThemeProvider
            theme={(appTheme) => ({
              ...appTheme,
              components: {
                ...appTheme.components,
                ...dataTableTheme(appTheme).components,
              },
            })}
          >
            <DataTable
              title={t('erpImportData')}
              data={renderData}
              columns={columns}
              options={options}
              getData={(seletedPageSize, currentPageVal) =>{
             
              }}
              resizable={true}
            />
          </ThemeProvider>
        </>

        <StepperPopup
          widthInput="800px"
          title={t('editERPInfo')}
          isOpen={updateERPForm}
          setIsOpen={setUpdateERPForm}
          steps={[
            {
              label: t('adinfo'),
              component: (
                <UpdateOperatorERP
                  operatorInfo={selectedRow}
                  setPayload={handleSetPayload}
                  confirmPayload={handleNext}
                  setCanNext={setCanNext}
                  submit={submit}
                  setSubmit={setSubmit}
                />
              ),
              nextAction: {
                label: t('LBLFurther'),
                icon: <ArrowForward />,
                action: () => {
                  setSubmit((prevState) => prevState + 1);
                },
                canNext: canNext,
              },
            },

            {
              label: t('confirmInfo'),
              component: (
                <ConfirmOperatorERP
                  payload={payload}
                  submit={confirmed}
                  setError={setError}
                  setSubmit={setConfirmed}
                  openFeedback={openFeedbackERP}
                />
              ),
              nextAction: {
                label: t('LBLSave'),
                action: () => {
                  setConfirmed((prevState) => prevState + 1);
                  setUpdateERPForm(false);
                  setLoading(true);
                  setSubmit(0);
                },
              },
            },
          ]}
        />
        <Feedback
          open={completedERP}
          title={t('erpUpdated')}
          message={
            <>
              <Typography align="center">{t('erpUpdatedsu')}</Typography>
            </>
          }
          handleClose={closeFeedback}
        />
        <Feedback_Error
          open={error}
          title={'Fehler'}
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          severity="warning"
          handleClose={() => {
            setError(false);
            setPayload(null);
          }}
        />
      </Paper>
    </>
  );
}
