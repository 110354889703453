import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { contractManagement ,backendForFrontend} from '../../../../../../services/utils/axios';
import { errorCodes } from '../../../../../../services/utils/errorCodes';
import { useAuth } from '../../../../../../services/contexts/AuthContext';

import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
/**
 * @param {PropTypes.InferProps<SecondCustomerConfirm.propTypes>} props
 */
function SecondCustomerConfirm({
  payload,
  openFeedback,
  submit,
  setError,
  setrfidError,
  setrfidSError,
  setemailError,
  setecustError,
  setEndCustomerId,
  setSubmit,
  editFlag
}) {
  const { token } = useAuth();
  const { t } = useTranslation();
  useEffect(() => {
    if (submit > 0) {
      if (editFlag) {
        updateUser();
      } else {
        createUser();
      }
    }
    setSubmit(0);
  }, [submit]);
  if (!payload) return null;
 const renderData = {
        'firstName': payload.firstName,
        'lastName': payload.lastName,
        'E-Mail': payload.email,
        'customerId': payload.customerId,
        'role': payload.role
      };
  const createUser = async () => {
    try {
      const newPayload = {
        "customerId" :  payload.customerId,
        "firstName" :  payload.firstName,
        "lastName" :  payload.lastName,
        "role": payload.role,
        "email" :  payload.email,
        "productPackage":'Prosessional',
        "customerType":  payload?.customerType,
        "sendEmail" :false,
        "isNewCustomer" : false
    };
      const newPayloadEntries = Object.entries(newPayload)
        .map(([key, value]) => {
          if (!value) return null;
          if (typeof value === 'object') {
            for (const field in value) {
              if (!value[field]) delete value[field];
            }
          }
          return [key, value];
        })
        .filter(Boolean);
      const filteredPayload = Object.fromEntries(newPayloadEntries);
      await backendForFrontend.post('/cpos/create-dashboard-user', newPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      openFeedback();
    } catch (error) {
      if (
        error?.error?.errorCodeResponse ==="ERR_RFID_ID_EXISTS"
      ) {
        setrfidError(error?.error?.errorDetail);
      }else if (
        error?.error?.errorCodeResponse ==="DB_CLIENT_ERROR"
      ) {
        setrfidSError(error?.error?.errorDetail);
      } else if (
        error?.error?.errorCodeResponse === errorCodes.duplicateEmail
      ) {
        setemailError(true);

      }
      else if (
        error?.error?.errorCodeResponse === errorCodes.duplicateUser
      ) {
        setecustError(true);

      }else      
       {
        setError(true);
      }
    }
  };
  const updateUser = async () => {
    try {
      const newPayload = {
        "customerId" :  payload.customerId,
        "firstName" :  payload.firstName,
        "lastName" :  payload.lastName,
        "customerType" :  payload.customerType,
        "role": payload.role,
        "email" :  payload.email,
        "sendEmail" : true
    };
      const newPayloadEntries = Object.entries(newPayload)
        .map(([key, value]) => {
          if (!value) return null;
          if (typeof value === 'object') {
            for (const field in value) {
              if (!value[field]) delete value[field];
            }
          }
          return [key, value];
        })
        .filter(Boolean);
      const filteredPayload = Object.fromEntries(newPayloadEntries);
      await backendForFrontend.post('/cpos/create-dashboard-user', filteredPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      openFeedback();
    } catch (error) {
      if (
        error?.error?.errorCodeResponse ==="ERR_RFID_ID_EXISTS"
      ) {
        setrfidError(error?.error?.errorDetail);
      }else if (
        error?.error?.errorCodeResponse ==="DB_CLIENT_ERROR"
      ) {
        setrfidSError(error?.error?.errorDetail);
      } else if (
        error?.error?.errorCodeResponse === errorCodes.duplicateEmail
      ) {
        setemailError(true);
        setEndCustomerId(error?.error?.errorMessage);
      } else {
        setError(true);
      }
    }
  };

  const renderKey = (key) => {
    switch (key) {
      case 'firstName':
        return t('vernom');

      case 'lastName':
        return t('lastname');

      case 'customerType':
        return t('LBLCustType');

      case 'role':
        return t('LBLRolle');
      default:
        return key;
    }
  };
  return (
    <>
      {Object.entries(renderData).map(([key, value]) => (
        <Stack
          key={key}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={4}
        >
          {value ? (
            <>
              <Typography variant="body1">{renderKey(key)}</Typography>
              {typeof value === 'string' ? (
                <Typography variant="body1" align="right" id={key}>
                  {value}
                </Typography>
              ) :  (
                <Stack my={2}>
                  {value.map((v) => (
                    <Typography variant="body1" align="right" id={v} key={v}>
                      {v}
                    </Typography>
                  ))}
                </Stack>
              )}
            </>
          ) : null}
        </Stack>
      ))}
    </>
  );
}
SecondCustomerConfirm.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any).isRequired,
  openFeedback: PropTypes.func.isRequired,
};
export default SecondCustomerConfirm;
