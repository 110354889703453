import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import React, { useMemo, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import {  TextField } from 'formik-mui';
import { useAuth } from '../../../../services/contexts/AuthContext';
import api from '../../../../Middleware/api/api';
import { useTranslation } from 'react-i18next';
import { validators } from '../../../../services/utils/validators';
let rows = [];
/**
 * @param {PropTypes.InferProps<OuterUserDetails.propTypes>} props
 */
const OuterUserDetails = ({
  setPayloadOne,
  confirmPayload,
  payload,
  submit,
  setSubmit,
  setCanNext,
  CreateUpdate,
}) => {
  const { t } = useTranslation();
  const { token } = useAuth();

  useEffect(() => {
    if (submit > 0) document.getElementById('subOne').click();
    setSubmit(0);

  }, [submit]);
  const initialValues = {
    firstName: payload?.firstName || '',
    lastName: payload?.lastName || '',
    email: payload?.email || '',
    role: payload?.role || '',
    productPackage: payload?.productPackage || ''
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(t('reqField')).label('First Name'),
        lastName: Yup.string().required(t('reqField')).label('Last Name'),
        email: Yup.string()
        .matches(validators.EMAIL, t('wrongEmail'))
        .email(t('wrongEmail')).required(t('emailReq')).label('E-Mail'),
        role: Yup.string().required(t('reqField')).label('Role'),
        productPackage: Yup.string().label('Product Package'),
      }),
    []
  );

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payloadVal = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: values.role,
      productPackage:'Prosessional'
    };
    setPayloadOne(payloadVal);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, true);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, true, true);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({
        touched,
        isValid,
      }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="firstName"
                name="firstName"
                label={t('vernom')}
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
                required
              />
          <Field
                fullWidth
                margin="none"
                component={TextField}
                id="lastName"
                name="lastName"
                label={t('lastname')}
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
                required
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>

          <Field
                fullWidth
                margin="none"
                component={TextField}
                id="email"
                name="email"
                label="E-Mail"
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
                required
              />
                    <Field
                fullWidth
                margin="none"
                component={TextField}
                id="role"
                name="role"
                label="Role"
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
                required
              />
            </Stack>

            <Button sx={{ display: 'none' }} id="subOne" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

OuterUserDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayloadOne: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OuterUserDetails;
