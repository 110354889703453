
import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import React, { useMemo, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import shadows from '@mui/material/styles/shadows';
import MenuItem from '@mui/material/MenuItem';
import { Select, TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
/**
 * @param {PropTypes.InferProps<OuterCompanyDetailsForm.propTypes>} props
 */
const OuterCompanyDetailsForm = ({
  payload,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click();
    setSubmit(0);
  }, [submit]);
  const initialValues = {
    customerId: payload?.customerId || '',
    customerType: payload?.customerType || '',
    companyName: payload?.companyName || '',
    street: payload?.address.street || '',
    careOf: payload?.address.careOf || '',
    city: payload?.address.city || '',
    zipCode: payload?.address.zipCode || '',

  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        customerId: Yup.string().required(t('reqField')).label('Customer Id'),
        customerType: Yup.string().required(t('reqField')).label('Customer Type'),
        companyName: Yup.string().required(t('reqField')).label('Company Name'),
        careOf: Yup.string().label('careOf'),
        street: Yup.string().required(t('LBLNameReq')).label(t('lblStName')),
        city: Yup.string().required(t('LBLLocReq')).label(t('city')),
        zipCode: Yup.number().typeError(t('NumericOnly')).required(t('ZipcodeReq')).label(t('PostalCode'))
      }),
    []
  );
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      customerId: values.customerId,
      customerType: values.customerType,
      companyName: values.companyName,
      address: {
        city: values.city,
        careOf: values.careOf,
        street: values.street,
        zipCode: values.zipCode,
        countryCode: 'DE', // always DE by default for Germany
      },
    };

    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ touched, isValid }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}
            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="companyName"
                name="companyName"
                label={t('companyName')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
              <span style={{ width: '100%' }} aria-hidden></span>
            </Stack>

            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="customerId"
                name="customerId"
                label={t('LBLCustomerNum')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
              <Field


                component={Select}
                formControl={{

                  fullWidth: true,
                  size: 'small',
                  margin: 'none',
                }}
                defaultValue={''}
                id="customerType"
                name="customerType"
                label={t('LBLCustType')}
                MenuProps={{
                  PaperProps: { sx: { boxShadow: shadows[3] } },
                }}
              >

                <MenuItem key={'Operator'} value={'Operator'} >
                  {t('oprtr')}
                </MenuItem>
                <MenuItem key={'Partner'} value={'Partner'} >
                  {'Partner'}
                </MenuItem>
              </Field>
            </Stack>


            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="straße-und-nummer"
                name="street"
                label={t('lblStName')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="c-o"
                name="careOf"
                label="c/o"
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="postleitzahl"
                name="zipCode"
                label={t('PostalCode')}
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
              <Field
                required
                fullWidth
                margin="none"
                component={TextField}
                id="ort"
                name="city"
                label={t('city')}
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
            </Stack>


            <Button sx={{ display: 'none' }} id="sub" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

OuterCompanyDetailsForm.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OuterCompanyDetailsForm;
