import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled, { withTheme } from 'styled-components/macro';
import { Bar } from 'react-chartjs-2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuth } from '../../services/contexts/AuthContext';
import Checkbox from '@mui/material/Checkbox';
import api from '../../Middleware/api/api';
import moment from 'moment';
import {
  formatDecimal,
  formatCurrency,
} from '../../services/utils/format-numbers';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Card as MuiCard,
  Chip as MuiChip,
  CardContent,
  CardHeader,
  Button as MuiButton,
  Menu,
  MenuItem,
  Select,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import { spacing } from '@mui/system';
import { alpha } from '@mui/material/styles';

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  margin-left: 7px;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: white !important;  
`;

const ChartWrapper = styled.div`
  height: 378px;
`;

function getDates(startDate, endDate, dateFormat) {
  let dates = [];
  let currentDate = moment(startDate).startOf('day');
  endDate = moment(endDate).startOf('day');

  while (currentDate <= endDate) {
    dates.push(moment(currentDate).format(dateFormat));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return [...new Set(dates)];
}

function calculatedates(days) {
  let date = new Date();
  let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  );
}

function BarChart({ theme }) {
  const { t, i18n } = useTranslation();
  const [anchorGraphType, setAnchorGraphType] = useState(null);
  const [anchorDataRange, setAnchorDataRange] = useState(null);
  const [anchorLocation, setAnchorLocation] = useState(null);
  const [stations, setStations] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [graphType, setGraphType] = useState(t('LBLAmountCharged'));
  const [dataRangeName, setDataRangeName] = useState(t('LBL7Days'));
  const [graphDataset, setGraphDataset] = useState([]);
  const [dataUnit, setDataUnit] = useState('DAYS');
  const [startDate, setStartDate] = useState(calculatedates(7));
  // eslint-disable-next-line no-unused-vars
  const [endDate, setEndDate] = useState(calculatedates(0));
  const [chartLabel, setChartLabel] = useState(
    getDates(startDate, endDate, 'DD MMM')
  );
  const [timestamp, setTimestamp] = useState(Date.now());
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    stations.length > 0 && selected.length === stations.length;
  const [currentValue2, setCurrentValue2] = useState([]);
  const [totalEnergyCharged, setTotalEnergyCharged] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalWallboxLicenses, setTotalWallboxLicenses] = useState(0);
  const [totalEndUsers, setTotalEndUsers] = useState(0);
  const [isStationsLoaded, setIsStationsLoaded] = useState(false);
  let yaxisStepValue = 0;
  let stepValue = 0;
  let max;

  const childStyles = {
    boxShadow: '1px 2px 5px #D0D0D0',
    width: '90%',
    padding: '5px',
    margin: 'auto',
    marginTop: '10px',
    height: '100%',
    border: '2px solid #D0D0D0',
    borderRadius: '8px',
    backgroundColor: 'white',
    position: 'relative',
  };

  const lebelStyle = {
    textAlign: 'right',
    fontSize: '12px',
    position: 'absolute',
    bottom: 0,
    right: '10px',
  };

  const { token } = useAuth();

  const params = useMemo(() => {

    const urlParams = new URLSearchParams();
    urlParams.set(
      'graphType',
      graphType === t('LBLAmountCharged') ? 'TOTAL_ENERGY' : 'ACTIVE_USERS'
    );
    urlParams.set('endDate', endDate);
    urlParams.set('startDate', startDate);
    urlParams.set('unit', dataUnit);
    urlParams.set('locations', selected.join());
    urlParams.set('timestamp', timestamp);
    return urlParams;
  }, [graphType, endDate, startDate, dataUnit, selected]);
  const getGraph = async () => {
    api.fetchGraph(token, params.toString()).then((response) => {
      if (response.data == null) {
        setChartData([]);
      } else {
        setChartData(
          response.data
        );
      }
    });
  };

  const getStations = async () => {
    api.fetchLocations(token, 0, 1000).then((response) => {
      setIsStationsLoaded(true);
      if (response.data.data == null) {
        setStations([]);
      } else {
        setStations(response.data.data.response);
        setSelected(
          response.data.data.response.map((station) => station.locationId)
        );
      }
    });
  };

  useEffect(() => {
    if (isStationsLoaded == true) {
      getGraph();
    }

  }, [graphType, dataUnit, startDate, selected]);
  useEffect(() => {
    getStations()
  }, []);

  const handleDataRangeClick = (event) => {
    setAnchorDataRange(event.currentTarget);
  };

  const handleLocationClick = (event) => {
    const rect = locationButtonComponent?.current?.getBoundingClientRect();
    setLeftPosition(rect?.left);
    setTopPosition(rect?.bottom);
    setAnchorLocation(event.currentTarget);
  };

  const handleGraphTypeClick = (event) => {
    setAnchorGraphType(event.currentTarget);
  };

  const handleGraphTypeChange = (event) => {
    if (event.target.getAttribute('value') !== null) {
      setGraphType(event.target.getAttribute('value'));
    }
    setAnchorGraphType(null);
  };

  const handleTestLocationChange = (event) => {
    if (event.target.value !== undefined) {
      if (
        event.target.value.includes('all') &&
        selected.length !== stations.length
      ) {
        setSelected(stations.map((station) => station.locationId));
      } else if (event.target.value.includes('all')) {
        setSelected([]);
      } else {
        setSelected(event.target.value);
      }
    } else {
      setAnchorLocation(null);
    }
  };

  useEffect(() => {
    let dateFormat = dataUnit === 'DAYS' ? 'DD.MM' : 'MM.YYYY';
    setCurrentValue2([]);
    setChartLabel(getDates(startDate, endDate, dateFormat));
  }, [startDate, dataUnit]);

  const handleDataRangeChange = (event) => {
    if (event.target.getAttribute('value') !== null) {
      let value = event.target.getAttribute('value');
      switch (value) {
        case t('LBL7Days'):
          setStartDate(calculatedates(7));
          setDataUnit('DAYS');
          setTimestamp(Date.now());
          break;
        case t('LBL30Days'):
          setStartDate(calculatedates(30));
          setDataUnit('DAYS');
          setTimestamp(Date.now());
          break;
        case t('LBL12Months'):
          setStartDate(calculatedates(365));
          setDataUnit('MONTHS');
          setTimestamp(Date.now());
          break;
        default:
          break;
      }
      setDataRangeName(value);
    }
    setAnchorDataRange(null);
  };

  const optimizedEffect = () => {
    const graphData = new Map();

    chartLabel.forEach((label) => {
      graphData.set(label, 0);
    });

    setTotalEnergyCharged(chartData?.data?.kpiInformation?.totalEnergyCharged);
    setTotalRevenue(chartData?.data?.kpiInformation?.totalRevenue);
    setTotalWallboxLicenses(chartData?.data?.kpiInformation?.totalWallboxLicenses);
    setTotalEndUsers(chartData?.data?.kpiInformation?.totalEndUsers);

    chartData?.data?.buildTotalEneryORActiveUsersVsDateGraphData?.forEach(
      ({ buildingUnits }) => {
        buildingUnits?.forEach(({ label, value }) => {
          const key = label.replace('-', '.');
          const currentValue = graphData.get(key) ?? 0;
          graphData.set(key, currentValue + value);
          setCurrentValue2((prevValue) => [...prevValue, value]);
        });
      }
    );

    const graphDataset = Array.from(graphData.values());
    setGraphDataset(graphDataset);
  };

  useEffect(optimizedEffect, [chartData]);

  //seting up the distribution of step size for values on Y-axis
  max = Math.max(...currentValue2);
  stepValue = Math.abs(max / 6);
  yaxisStepValue = Math.round(stepValue) >= 1 ? Math.round(stepValue) : 1;

  const graphData = (canvas) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0)');

    return {
      labels: chartLabel,
      datasets: [
        {
          label: graphType,
          fill: true,
          backgroundColor: '#DCE3CE',
          borderColor: theme.palette.secondary.main,
          tension: 0,
          data: graphDataset,
        },
      ],
    };
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        align: 'end',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label +=
                graphType === t('LBLAmountCharged')
                  ? formatDecimal(context.parsed.y) + ' kWh'
                  : context.parsed.y;
            }
            return label;
          },
        },
      },
    },
    locale: 'de-DE',
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
        title: {
          display: true,
          text: '',
        },
      },
      y: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
        beginAtZero: true,
        fontStyle: 'bold',
        fontWeight: 'bold',
        ticks: {
          min: 0,
          max: max,
          stepSize: yaxisStepValue,
          suggestedMin: 1,
          fontStyle: 'bold',
          fontWeight: 'bold',
          callback: function (yaxisStepValue) {
            return `${this.getLabelForValue(Number(yaxisStepValue))}`;
          },
        },
        title: {
          display: true,
          text:
            graphType === 'Total energy charged' ||
              graphType === 'Geladene Energiemenge'
              ? t('LBLAmountCharged') + ' (kWh)'
              : t('LBLActiveUsers'),
          fontColor: '#000000',
        },
        tooltips: {
          enabled: false,
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.yLabel}`;
            },
            title: function (tooltipItem) {
              return `<b>${tooltipItem[0].xLabel}</b>`;
            },
          },
        },
      },
    },
  };

  const [leftPosition, setLeftPosition] = useState('0');
  const [topPosition, setTopPosition] = useState('0');
  const locationButtonComponent = useRef(null);
  return (
    <Card mb={6}>
      <Grid container xs={12} lg={12} md={12}>
        <Grid
          spacing={5}
          item
          md={3}
          style={{
            width: '100%',
            height: '110px',
            marginBottom: '10px',
            backgroundColor: 'transparent',
          }}
        >
          {' '}
          <div style={childStyles}>
            <Typography
              variant="h3"
              style={{ width: '100%' }}
              fontSize={'small'}
              display="inline"
            >
              {t('LBLAmountenergyCharged')}
            </Typography>

            <p style={lebelStyle}>
              {totalEnergyCharged !== null && totalEnergyCharged > 0
                ? i18n.language === 'en'
                  ? formatDecimal(totalEnergyCharged).replace(/,/g, '.') +
                  ' kWh'
                  : formatDecimal(totalEnergyCharged) + ' kWh'
                : 0 + ' kWh'}
            </p>
          </div>
        </Grid>

        <Grid
          item
          md={3}
          style={{
            width: '100%',
            height: '110px',
            backgroundColor: 'transparent',
          }}
        >
          {' '}
          <div style={childStyles}>
            <Typography
              variant="h3"
              style={{ width: '100%' }}
              fontSize={'small'}
              display="inline"
            >
              {t('LBLTotalSales')}
            </Typography>

            <p style={lebelStyle}>
              {totalRevenue !== null && totalRevenue > 0
                ? i18n.language === 'en'
                  ? formatCurrency(totalRevenue)
                    .replace(/,/g, ' ')
                    .replace(/\./g, ',')
                    .replace(' ', '.')
                  : formatCurrency(totalRevenue)
                : null}
            </p>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          style={{
            width: '100%',
            height: '110px',
            backgroundColor: 'transparent',
          }}
        >
          {' '}
          <div style={childStyles}>
            <Typography
              variant="h3"
              style={{ width: '100%' }}
              fontSize={'small'}
              display="inline"
            >
              {t('LBLCharginStationsLicences')}
            </Typography>

            <p style={lebelStyle}>{totalWallboxLicenses} </p>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          style={{
            width: '100%',
            height: '110px',
            backgroundColor: 'transparent',
          }}
        >
          {' '}
          <div style={childStyles}>
            <Typography
              variant="h3"
              style={{ width: '100%' }}
              fontSize={'small'}
              display="inline"
            >
              {t('LBLTotalUsers')}
            </Typography>

            <p style={lebelStyle}>{totalEndUsers} </p>
          </div>
        </Grid>
      </Grid>
      <CardHeader
        action={
          <div>
            <Button
              endIcon={<ArrowDropDownIcon fontSize="small" />}
              size="small"
              variant="contained"
              id="locationButton"
              color="primary"
              aria-owns={anchorLocation ? 'chart-location' : undefined}
              aria-haspopup="true"
              onClick={handleLocationClick}
              ml={4}
              ref={locationButtonComponent}
              style={{ minWidth: '150px', minHeight: '32px', marginRight: '10px', }} 
            >
              {t('LBLLocation')} 
              <Chip label={selected.length} size="small"/>
            </Button>

            <Button
              endIcon={<ArrowDropDownIcon fontSize="small" />}
              size="small"
              variant="contained"
              color="primary"
              aria-owns={anchorGraphType ? 'chart-graph-type' : undefined}
              aria-haspopup="true"
              onClick={handleGraphTypeClick}
              ml={4}
              style={{ minWidth: '150px', minHeight: '32px', marginRight: '10px' }}  
            >
              {graphType === 'Total energy charged' ||
                graphType === 'Geladene Energiemenge'
                ? t('LBLAmountCharged')
                : t('LBLActiveUsers')}
            </Button>
            <Button
              endIcon={<ArrowDropDownIcon fontSize="small" />}
              size="small"
              variant="contained"
              color="primary"
              aria-owns={anchorDataRange ? 'chart-date-range' : undefined}
              aria-haspopup="true"
              onClick={handleDataRangeClick}
              ml={4}
              style={{ minWidth: '150px', minHeight: '32px' }}  
            >
              {dataRangeName == 'Last 7 days' ||
                dataRangeName == 'Letzte 7 Tage'
                ? t('LBL7Days')
                : dataRangeName == 'Last 30 days' ||
                  dataRangeName == 'Letzte 30 Tage'
                  ? t('LBL30Days')
                  : t('LBL12Months')}
            </Button>

            <Menu
              id="chart-graph-type"
              anchorEl={anchorGraphType}
              open={Boolean(anchorGraphType)}
              onClose={handleGraphTypeChange}
            >
              <MenuItem
                key={t('LBLAmountCharged')}
                name="GraphType"
                value={t('LBLAmountCharged')}
                onClick={handleGraphTypeChange}
              >
                {t('LBLAmountCharged')}
              </MenuItem>


              <MenuItem
                key={t('LBLActiveUsers')}
                name="GraphType"
                value={t('LBLActiveUsers')}
                onClick={handleGraphTypeChange}
              >
                {t('LBLActiveUsers')}
              </MenuItem>

            </Menu>

            <Menu
              id="chart-date-range"
              anchorEl={anchorDataRange}
              open={Boolean(anchorDataRange)}
              onClose={handleDataRangeChange}
            >
              <MenuItem
                key={t('LBL7Days')}
                name="datarange"
                value={t('LBL7Days')}
                onClick={handleDataRangeChange}
              >
                {t('LBL7Days')}
              </MenuItem>
              <MenuItem
                key={t('LBL30Days')}
                name="datarange"
                value={t('LBL30Days')}
                onClick={handleDataRangeChange}
              >
                {t('LBL30Days')}
              </MenuItem>
              <MenuItem
                key={t('LBL12Months')}
                name="datarange"
                value={t('LBL12Months')}
                onClick={handleDataRangeChange}
              >
                {t('LBL12Months')}
              </MenuItem>
            </Menu>

            <Select
              id="chart-location"
              variant="filled"
              color="primary"
              size="small"
              style={{ display: 'none' }}
              anchorEl={anchorLocation}
              open={Boolean(anchorLocation)}
              labelId="locationButton"
              input={<OutlinedInput sx={{ fontSize: '2rem' }} label="Tag" />}
              multiple
              value={selected}
              onChange={handleTestLocationChange}
              renderValue={(selected) => selected.length}
              IconComponent={() => null}
              MenuProps={{
                PaperProps: {
                  sx: {
                    left: `${leftPosition}px !important`,
                    top: `${topPosition}px !important`,
                    maxHeight: '200px !important',
                    fontSize: '2 !important',
                  },
                },
                //getContentAnchorEl: null,
                autoFocus: false,
              }}
              onClose={handleTestLocationChange}
              disableUnderline
            >
              <MenuItem value="all" divider id="allLocations">
                <Checkbox
                  size="small"
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < stations.length
                  }
                />
                <ListItemText primary={t('LBLALLLocations')} />
              </MenuItem>
              {stations.map((station) => (
                <MenuItem
                  size="small"
                  id={station.locationId + ': ' + station.locationName}
                  key={station.locationId}
                  value={station.locationId}
                >
                  <Checkbox
                    size="small"
                    checked={selected.indexOf(station.locationId) > -1}
                  />
                  <ListItemText primaryTypographyProps={{ fontSize: '10px' }}>
                    {station.locationId + ': ' + station.locationName}
                  </ListItemText>
                </MenuItem>
              ))}
            </Select>
          </div>
        }
      />
      <CardContent>
        <ChartWrapper>
          <Bar data={graphData} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(BarChart);