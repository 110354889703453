import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import React, { useMemo, useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import {  TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { validators } from '../../../../../../services/utils/validators';
/**
 * @param {PropTypes.InferProps<InnerUserConfirm.propTypes>} props
 */
const InnerUserConfirm = ({
  payload,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click();
    setSubmit(0);
  }, [submit]);
  const initialValues = {
    firstName: payload?.firstName || '',
    lastName: payload?.lastName || '',
    email: payload?.email || '',
    role: payload?.role || ''
   
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(t('firstnamereq')).label(t('vernom')),
        lastName: Yup.string().required(t('firstnamereq')).label(t('lastname')),
        email: Yup.string()
        .matches(validators.EMAIL, t('wrongEmail'))
        .email(t('wrongEmail')).required(t('emailReq')).label('E-Mail'),
        role: Yup.string().required(t('reqField')).label('Role')  }),
    []
  );
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role:values.role,
    };

    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ touched, isValid }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}
            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="vorname"
                name="firstName"
                label={t('vernom')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
         <Field
                fullWidth
                margin="none"
                component={TextField}
                id="lastName"
                name="lastName"
                label={t('lastname')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
            </Stack>          
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="e-mail"
                name="email"
                label="E-Mail"
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
                required
              />
            <Field
                fullWidth
                margin="none"
                component={TextField}
                id="role"
                name="role"
                label={t('LBLRolle')}
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
                required
              />
            </Stack>

            <Button sx={{ display: 'none' }} id="sub" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

InnerUserConfirm.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InnerUserConfirm;
