import React, { useMemo, useState, useEffect, lazy, Suspense } from 'react';
import IconButton from '@mui/material/IconButton';
import MuiPaper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/system/Box';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import useSWR from 'swr';
import FileOpen from '@mui/icons-material/FileOpenOutlined';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../services/contexts';
import {
  formatCurrency,
  formatDecimal,
} from '../../services/utils/format-numbers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from '../Users/components/CreateUser/LightTooltip';
import { Item } from './styles';
import api from '../../Middleware/api/api';
import {
  PAGE_SIZE,
  BillingDataForOrigin,
} from '../../services/utils/units-constants';
import BillStatusLabel from '../../components/BillStatusLabel';
import {
  Button,
  TableCell,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from '@mui/material';

const Feedback = lazy(() => import('../../components/DataTable/Feedback'));

const Paper = styled(MuiPaper)(spacing);

const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

// eslint-disable-next-line no-unused-vars
const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4"], &[data-colindex="5"]':
              {
                '& > *': {
                  display: 'flex',
                  justifyContent: 'right',
                  textAlign: 'right',
                },
                '& .MuiButton-root': {
                  margin: 'auto',
                },
              },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4"], &[data-colindex="5"]':
              {
                '& > *': {
                  justifyContent: 'right',
                  textAlign: 'right',
                },
                '& .MuiButton-root': {
                  margin: 'auto',
                },
              },
          },
        },
      },
    },
  });

export function EnhancedTable({ selectedYear, billingType, contractKey }) {
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [loadingPDF, setLoadingPDF] = useState([]);
  const [error, setError] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);
  const [successfullBilligStatusChange] = useState(0);
  const { customerId } = useParams();
  const { token } = useAuth();
  const [changeFlag, setChangeFlag] = useState(Date.now());
  const [openTooltipId, setOpenTooltipId] = useState(null);

  const [operatorData, setOperatorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);

  const handleIConClick = (billId) => {
    setOpenTooltipId(billId);
  };
  const handleTooltipClose = () => {
    setOpenTooltipId(null);
  };
  const handleTooltipOpen = (billId) => {
    setOpenTooltipId(billId);
  };

  const renderPaymentIntents = (props) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            showstripeSepaPaymentIntentIdAndStripeBillPaymentStatus(props),
        }}
      />
    );
  };

  const getBillCollectionDate = (props) => {
    return props.billCollectionDate != null
      ? new Date(props.billCollectionDate).toLocaleDateString()
      : t('LBLBillNotYetSent');
  };
  const getStripePaymentIntent = (props) => {
    return props.stripeSepaPaymentIntentId != null
      ? props.stripeSepaPaymentIntentId
      : '';
  };
  const getStripePaymentStatus = (props) => {
    return props.stripeSepaBillPaymentStatus != null
      ? props.stripeSepaBillPaymentStatus
      : '';
  };

  function showstripeSepaPaymentIntentIdAndStripeBillPaymentStatus(props) {
    let billCollectionDateSection = '';
    billCollectionDateSection =
      t('LBLBillCollectionDate') + ' : ' + getBillCollectionDate(props);
    let paymentIntentSection = '';
    paymentIntentSection =
      '<br>' +
      t('stripeSepaPaymentIntentId') +
      ' : ' +
      getStripePaymentIntent(props);
    let paymentStatusSection = '';
    paymentStatusSection =
      '<br>' +
      t('stripeSepaPaymentStatus') +
      ' : ' +
      getStripePaymentStatus(props);
    return (
      billCollectionDateSection + paymentIntentSection + paymentStatusSection
    );
  }

  const getBills = async (seletedPageSize, currentPageVal) => {
    const params = new URLSearchParams({
      endCustomerId: customerId,
      year: selectedYear,
      origin: BillingDataForOrigin.CONTRACT_PAGE,
      page: currentPageVal,
      size: seletedPageSize,
    });
    api
      .fetchBills(token, params)
      .then((response) => {
        if (response.data.data == null) {
          setOperatorData([]);
        } else {
          setTotalRecords(response?.data?.data?.totalNumberOfRecords);

          setOperatorData(response.data?.data?.response);
        }
      })
      .catch((error) => {
        setOperatorData([]);
      });
  };
  const sendPdfBillEmail = (selected) => {
    if (token) {
      try {
        api.sendBillsPdfEmail(token, selected).then(
          () => {
            setShowFeedback(true);
          },
          () => {
            setShowErrorFeedback(true);
          }
        );
      } catch (exception) {
        setShowErrorFeedback(true);
      }
    }
  };

  useEffect(() => {
    getBills(pageSize, currentPage);
  }, [currentPage, pageSize,selectedYear,changeFlag]);
  const columns = useMemo(
    () => [
      {
        name: 'billNumber',
        label: t('billnum'),
        options: {
          filter: false,
          sort: true,
          display: billingType === 'WITH_BILLING',
        },
      },
      {
        name: 'month',
        label: t('monat'),
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'energyLoaded',
        label: t('LBLAmountCharged'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            let valueData = value;
            if (i18n.language === 'en') {
              valueData = valueData.replace(/,/g, '.');
              valueData = formatDecimal(valueData);
            }
            return (
              <Typography align="right" paddingRight={2}>
                {value !== null ? valueData + ' kWh' : ''}
              </Typography>
            );
          },
        },
      },
      {
        name: 'totalTaxedCosts',
        label: t('totalCost'),
        options: {
          filter: false,
          sort: false,
          display: billingType === 'WITH_BILLING',
        },
      },
      {
        name: 'billingStatus',
        label: t('billStatus'),
        options: {
          filter: billingType === 'WITH_BILLING',
          sort: false,
          display: billingType === 'WITH_BILLING',

          filterType: 'custom',
          filterOptions: {
            fullWidth: true,
            logic: (bill, filters, row) => {
              if (filters.length) return !filters.includes(bill);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              const list = [
                ...new Set(
                  data?.data?.billDetails?.billCosts?.map(
                    (item) => item.billingStatus
                  )
                ),
              ];
              return (
                <FormControl
                  size="small"
                  sx={{
                    marginLeft: '0',
                  }}
                >
                  <InputLabel
                    id="key-label"
                    sx={{
                      marginLeft: '-10px',
                    }}
                  >
                    {t('LBLbillingStatus')}
                  </InputLabel>
                  <Select
                    labelId="key-label"
                    select
                    multiple
                    variant="standard"
                    label={t('LBLbillingStatus')}
                    value={filterList[index]}
                    renderValue={(selected) => {
                      return selected.map((item) => item).join(',');
                    }}
                    onChange={(event) => {
                      filterList[index] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {list.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          color="primary"
                          checked={filterList[index].indexOf(item) > -1}
                        />
                        <BillStatusLabel title={item?.toLowerCase()} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            },
          },
          customBodyRender: (value) => {
            return (
              <BillStatusLabel
                style={{ textAlign: 'center' }}
                title={value?.toLowerCase()}
              />
            );
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
            >
              {t('billStatus')}
            </TableCell>
          ),
        },
      },
      {
        name: 'open',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          display: billingType === 'WITH_BILLING',
        },
      },
      {
        name: 'details',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          customBodyRender(value) {
            return (
              <>
                {value.billingStatus == 'NOT_APPLICABLE' &&
                billingType == 'NO_BILLING' ? null : billingType ==
                    'WITH_BILLING' && value.billingStatus == 'GENERATED' ? (
                  <>
                    <Button
                      variant="text"
                      color="primary"
                      type="submit"
                      onClick={() => {
                        sendPdfBillEmail([value]);
                      }}
                    >
                      {t('LBLButtonSendBill')}
                    </Button>
                  </>
                ) : value.paymentMethod == 'STRIPE_SEPA' ? (
                  <>
                    <Item>
                      <Typography fontWeight="fontWeightMedium">
                        <InfoIcon
                          onClick={() => handleIConClick(value.billId)}
                          color="action"
                          fontSize="small"
                          cl
                        />
                      </Typography>
                    </Item>

                    <LightTooltip
                      open={openTooltipId === value.billId}
                      onClose={handleTooltipClose}
                      placement="top-start"
                      title={renderPaymentIntents(value) || ''}
                      onOpen={() => handleTooltipOpen(value.billId)}
                    ></LightTooltip>
                  </>
                ) : null}
              </>
            );
          },
        },
      },
    ],
    [operatorData, billingType, contractKey, renderPaymentIntents]
  );

  const renderData = useMemo(() => {
    const billCosts = operatorData?.billDetails?.billCosts;

    return billCosts
      ?.sort((entry1, entry2) => entry2.monthNumber - entry1.monthNumber)
      .map((entry) => {
        const loading = loadingPDF.includes(entry.billNumber);

        const {
          monthNumber,
          yearNumber,
          energyLoaded,
          taxedFixCosts,
          taxedVarCosts,
          totalTaxedCosts,
          billingStatus,
          ...billCost
        } = entry;
        entry['month'] = `${months[monthNumber - 1]} ${yearNumber}`;

        const newEntry = {
          ...billCost,
          details: entry,
          energyLoaded: `${formatDecimal(energyLoaded)}`,
          taxedFixCosts: formatCurrency(taxedFixCosts),
          taxedVarCosts: formatCurrency(taxedVarCosts),
          totalTaxedCosts:
            totalTaxedCosts !== null ? formatCurrency(totalTaxedCosts) : null,
          month: `${months[monthNumber - 1]} ${yearNumber}`,
          billingStatus: billingStatus,
          open:
            billingStatus !== null ? (
              <Box position="relative">
                <IconButton
                  data-bill-number={entry.billNumber}
                  disabled={loading}
                  aria-label={`open bill ${entry.billNumber}`}
                  href={`${window._env_.REACT_APP_BOOKING_SERVICE_API}/bookings/pdf/${entry.billNumber}?verificationId=${entry.billId}`}
                  target="_blank"
                >
                  <FileOpen />
                </IconButton>
                {loading && (
                  <CircularProgress
                    size={38}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            ) : null,
        };

        return newEntry;
      });
  }, [operatorData, loadingPDF, selectedYear]);

  return (
    <Paper>
      <>
        <ThemeProvider
          theme={(appTheme) => ({
            ...appTheme,
            components: {
              ...appTheme.components,
              ...dataTableTheme(appTheme).components,
            },
          })}
        >
          <DataTable
            title={t('invoicesview')}
            data={renderData}
            columns={columns}
            resizable={true}
            options={{
              filter: true,
              search: true,
              textLabels: {
                body: {
                  noMatch: t('noinvoices'),
                },
              },
              selectableRows: 'none',
              currentPage: currentPage,
              totalRecords: totalRecords,
            }}
            getData={(seletedPageSize, currentPageVal) =>{
              setCurrentPage(currentPageVal);
              setPageSize(seletedPageSize);
            }}
          />
        </ThemeProvider>
        <Suspense fallback={<div />}>
          <p>
            <Feedback
              open={error}
              title={t('noinfound')}
              severity="warning"
              message={t('nopdf')}
              handleClose={() => setError(false)}
            />
          </p>
        </Suspense>
        <Suspense fallback={<div />}>
          <p>
            <Feedback
              open={showFeedback}
              title={t('LBLSendBillPdfMailSuccess')}
              severity="success"
              message={
                <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
                  {t('MSGSendBillPdfMailSuccess')}
                </Typography>
              }
              handleClose={() => {
                setShowFeedback(false);
                setChangeFlag(new Date());
              }}
            />
          </p>
        </Suspense>

        <Suspense fallback={<div />}>
          <p>
            <Feedback
              open={showErrorFeedback}
              title={t('LBLSendBillPdfMailError')}
              severity="warning"
              message={
                <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
                  {t('MSGSendBillPdfMailError')}
                </Typography>
              }
              // severity="warning"
              handleClose={() => {
                setShowErrorFeedback(false);
              }}
            />
          </p>
        </Suspense>
      </>
    </Paper>
  );
}