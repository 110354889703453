import { memo, useEffect, useRef, useState } from 'react';
import { Text, Tooltip } from  '@mui/material';
import React from 'react';
import LightTooltip from '../components/LightTooltip';
import Typography from '@mui/material/Typography';
const OverflowText = ({ label,maxwidthValue}) => {
    const openTooltip = (name) => () => setTooltipOpen(name);
    const closeTooltip = () => setTooltipOpen('');
    const [tooltipOpen, setTooltipOpen] = useState('');
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
      setTooltipOpen('open')
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
      setTooltipOpen('')
    };
  return (

    <div
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    style={{
      width: maxwidthValue,
      height: '40px',
      cursor: isHovered ? 'pointer' : '',
    }}
  >
   <Typography variant="body1"
    style={{
      maxWidth: maxwidthValue,
    }}
     className='text-truncate' align="right" id="ellipsis-ex"
     onClick={() => {
      if(tooltipOpen === 'open'){
        setTooltipOpen('')
      }else{
        setTooltipOpen('open')
      }
    }} 
    
    >
                     <LightTooltip
                  open={tooltipOpen === 'open'}
                  onOpen={openTooltip('open')}
                  onClose={closeTooltip}
                  placement="bottom-start"
                  title={
                    <>
                      <Typography> {label}</Typography>
                
                    </>
                  }
                >
                </LightTooltip>
{label}
    </Typography>
  </div>
 
  );
};

export default memo(OverflowText);