import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useAuth } from '../../../services/contexts';
import api from '../../../Middleware/api/api';
import { useTranslation } from 'react-i18next';
function LocationConfirmation({ payload, submit, setSuccess, setError }) {
  const { t } = useTranslation();
  const { token } = useAuth();
  useEffect(() => {
    if (submit) {
      let wallBoxItems = [];
      if (payload?.numOfChargingStationsOne > 0) {
        let objOne = {
          numOfChargingStations: payload?.numOfChargingStationsOne,
          chargingStationModel: payload?.chargingStationModelOne,
        };
        wallBoxItems.push(objOne);
      }
      if (payload?.numOfChargingStationsTwo > 0) {
        let objTwo = {
          numOfChargingStations: payload?.numOfChargingStationsTwo,
          chargingStationModel: payload?.chargingStationModelTwo,
        };
        wallBoxItems.push(objTwo);
      }
      if (payload?.numOfChargingStationsThree > 0) {
        let objThree = {
          numOfChargingStations: payload?.numOfChargingStationsThree,
          chargingStationModel: payload?.chargingStationModelThree,
        };
        wallBoxItems.push(objThree);
      }
      if (payload?.numOfChargingStationsFour > 0) {
        let objFour = {
          numOfChargingStations: payload?.numOfChargingStationsFour,
          chargingStationModel: payload?.chargingStationModelFour,
        };
        wallBoxItems.push(objFour);
      }
      let reqBody = {
        locationName: payload.locationName,
        locationAddress: payload?.locationAddress,
        cpoId: payload.cpoId,
        cpoName: payload.cpoName,
        wallBoxesData: wallBoxItems,
        isEmsConnected: payload.isEmsConnected,
        emsModel: payload.emsModel,
        emsId: payload.emsId,
      };
      api.createNewLocation(token, reqBody).then(
        (response) => {
          setSuccess(response);
        },
        // eslint-disable-next-line no-unused-vars
        (requestError) => {
          if (requestError.response && requestError.response.data) {
            setError(requestError.response.data.error); 
          } else {
            setError(true);  
          }
        }
      );
    }
  }, [submit]);
  return (
    <>
      <Grid
        sx={{
          fontSize: '23px',
          marginTop: 2,
          marginLeft: 6,
        }}
      >
        <b> {t('Confirmlocinfo')}</b>
      </Grid>{' '}
      <br></br>
      <Grid
        sx={{
          fontSize: '16px',
          marginTop: 2,
          marginRight: 20,
          marginLeft: 6,
        }}
      >
        <Grid item md={6} sx={{ fontSize: '18px' }}>
          <b>{t('LBLPropInfo')} </b>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item md={6}>
            {t('Lname')}
          </Grid>
          <Grid item md={6}>
            {payload.locationName}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item md={6}>
            {t('LBLAddress')}
          </Grid>
          <Grid item md={6}>
            {payload.locationAddress?.careOf
              ? 'c/o ' + payload.locationAddress?.careOf
              : ''}
            {payload.locationAddress?.careOf ? <br /> : ''}
            {payload.locationAddress?.street} <br />
            {payload.locationAddress?.zipCode} {payload.locationAddress?.city}
          </Grid>
        </Grid>
        <br></br>
        <Grid item md={6} sx={{ fontSize: '18px' }}>
          <b>{t('MenuOp')}</b>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item md={6}>
            {t('LBLOpId')}
          </Grid>
          <Grid item md={6}>
            {payload.cpoId}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item md={6}>
            {t('opName')}
          </Grid>
          <Grid item md={6}>
            {payload.cpoName}
          </Grid>
        </Grid>
        <br></br>
        <br></br>
  {payload.infraType==true?  <div>
    <Grid item md={6} sx={{ fontSize: '18px' }}>
          <b>{t('LBLCharginInfra')}</b>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item md={6}>
            {' '}
            {t('ChargingstInfo')}
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'left' }}>
            {payload?.numOfChargingStationsOne > 0
              ? payload?.numOfChargingStationsOne +
                'x ' +
                payload?.chargingStationModelOne
              : ''}
            {payload?.numOfChargingStationsOne > 0 ? <br /> : ''}
            {payload?.numOfChargingStationsTwo > 0
              ? payload?.numOfChargingStationsTwo +
                'x ' +
                payload?.chargingStationModelTwo
              : ''}
            {payload?.numOfChargingStationsTwo > 0 ? <br /> : ''}
            {payload?.numOfChargingStationsThree > 0
              ? payload?.numOfChargingStationsThree +
                'x ' +
                payload?.chargingStationModelThree
              : ''}
            {payload?.numOfChargingStationsThree > 0 ? <br /> : ''}
            {payload?.numOfChargingStationsFour > 0
              ? payload?.numOfChargingStationsFour +
                'x ' +
                payload?.chargingStationModelFour
              : ''}
            {payload?.numOfChargingStationsFour > 0 ? <br /> : ''}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          <Grid item md={6}>
            {t('emsLinked')}
          </Grid>
          <Grid item md={6}>
            {payload.isEmsConnected ? t('yes') : t('no')}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{
            marginTop: 1,
            display: payload.isEmsConnected ? 'show' : 'none',
          }}
        >
          <Grid item md={6}>
            {' '}
            {t('emsModels')}
          </Grid>
          <Grid item md={6}>
            {payload.emsModel}
          </Grid>
        </Grid>
    </div>:<div></div>}
        <br></br>
        <br></br>
      </Grid>
    </>
  );
}

export default LocationConfirmation;