import React, { useCallback, useState } from 'react';
import { Item, Spacer, View } from './styles';
import { CardContent, Typography, Stack, Link, useTheme } from '@mui/material';
import { contractManagement } from '../../services/utils/axios';
import { useAuth } from '../../services/contexts/AuthContext';
import { useSWRConfig } from 'swr';
import LogoLoader from '../../components/LogoLoader';
import { useTranslation } from 'react-i18next';
import OverflowText from '../../components/OverflowText';
function LocationInfoComponent({ contract, setErrorLabel, setSuccessLabel }) {
  const { t } = useTranslation();
  const { token } = useAuth();
  const { mutate } = useSWRConfig();
  const [loading, setLoading] = useState(false);

  const contractNull_Undefined = {
    Standort: '',
    Parkplatznummer: '',
    AutorisierungUber: '',
    RFIDTag: '',
    RFIDSTag: '',
    Status: '',
  };
  const contractLocalAdminOrGlobalAdmin = {
    AutorisierungUber:
      contract?.authType == 'APP_AND_RFID' ? 'APP, RFID' : contract?.authType,
    RFIDTag:
      contract?.rfidTags?.length > 0 ? contract?.rfidTags.join(', ') : null,
    RFIDSTag:
      contract?.rfidSerialId?.length > 0
        ? contract?.rfidSerialId.join(', ')
        : null,
    Status: contract?.contractStatusMapping,
  };

  const contractRegular = {
    Standort: `${contract?.locationId + ' : ' + contract?.locationName}`,
    Parkplatznummer:
      contract?.parkingLotNumbers?.length > 0
        ? contract?.parkingLotNumbers.join(', ')
        : contract?.parkingLotNumbers,
    AutorisierungUber:
      contract?.authType == 'APP_AND_RFID' ? 'APP, RFID' : contract?.authType,
    RFIDTag:
      contract?.rfidTags?.length > 0 ? contract?.rfidTags.join(', ') : null,
    RFIDSTag:
      contract?.rfidSerialId?.length > 0
        ? contract?.rfidSerialId.join(', ')
        : null,
    Status: contract?.contractStatusMapping,
  };
  const locationData =
    contract === undefined || contract === null
      ? contractNull_Undefined
      : contract?.userType === 'LOCAL_ADMIN' ||
          contract?.userType === 'GLOBAL_ADMIN'
        ? contractLocalAdminOrGlobalAdmin
        : contractRegular;

  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'RFIDTag':
          return locationData.AutorisierungUber !== 'APP' && 'RFID-Tag';
        case 'RFIDSTag':
          return locationData.AutorisierungUber !== 'APP' && t('rfIdSN');
        case 'Standort':
          return t('LBLLocation');
        case 'Parkplatznummer':
          return t('LBLParkingLotNum');
        case 'AutorisierungUber':
          return t('authuser');
        default:
          return key;
      }
    },
    [contract]
  );

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await contractManagement.post(
        `/send-invitation-email/${contract?.contractKey}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      mutate([
        `/send-invitation-email/${contract?.contractKey}`,
        token,
        'contractManagement',
      ]);
      setSuccessLabel(true);
      setLoading(false);
    } catch (error) {
      setErrorLabel(true);
      setLoading(false);
    }
  };

  return (
    <View>
      <LogoLoader loading={loading} />
      <CardContent>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          {t('lblAuthInfo')}
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          {locationData && (
            <>
              {Object.entries(locationData).map(([key, value]) => {
                return (
                  <>
                    {renderKey(key) && (
                      <>
                        {value != null ? (
                          <Item key={key}>
                            <Typography fontWeight="fontWeightMedium">
                              {renderKey(key)}
                            </Typography>
                            {key === 'Status' ? (
                              contract?.contractStatus == 'CREATED_UNSIGNED' ? (
                                <Stack
                                  size="small"
                                  mr={1}
                                  mb={1}
                                  color={value.colourCode}
                                >
                                  <Link
                                    color="text.secondary"
                                    href="#"
                                    aria-describedby="ContactComponent-popover"
                                    onClick={handleClick}
                                  >
                                    {t(value.enumName)} ({t('resendEmail')}?)
                                  </Link>
                                </Stack>
                              ) : (
                                <Typography
                                  align="center"
                                  color={value.colourCode}
                                >
                                  {t(value.enumName)}
                                </Typography>
                              )
                            ) : key === 'Rechnungsadresse' ? (
                              <Typography
                                fontWeight="fontWeightRegular"
                                align="right"
                              >
                                {contract?.contractAddress?.careOf
                                  ? 'c/o ' + contract?.contractAddress?.careOf
                                  : ''}
                                {contract?.contractAddress?.careOf ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {contract?.contractAddress?.street} <br />
                                {contract?.contractAddress?.zipCode}{' '}
                                {contract?.contractAddress?.city}
                              </Typography>
                            ) : key === 'RFIDTag' ? (
                              <Typography
                                fontWeight="fontWeightRegular"
                                align="right"
                              >
                                <OverflowText
                                  label={value}
                                  maxwidthValue={'250px'}
                                >
                                  {' '}
                                </OverflowText>
                              </Typography>
                            ) : (
                              <Typography
                              fontWeight="fontWeightRegular"
                              align="right"
                            >
                              <OverflowText
                                label={value}
                                maxwidthValue={'250px'}
                              >
                                {' '}
                              </OverflowText>
                            </Typography>
                            )}
                          </Item>
                        ) : null}
                      </>
                    )}
                  </>
                );
              })}
            </>
          )}
        </Typography>
      </CardContent>
    </View>
  );
}

export default LocationInfoComponent;