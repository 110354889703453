import React from 'react';
import { Item, Spacer, View } from './styles';
import { CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../services/utils/helpers';
import { GERMAN_DATE_FORMAT } from '../../services/utils/date-format-constants';

export default function ChargingStationComponent({ chargingStation }) {
  const { t } = useTranslation();
  return (
    <View>
      <CardContent>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          {t('LBLCharginStationInfo')}
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item key={0}>
            <Typography fontWeight="fontWeightMedium">Station-ID</Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.cpId}
            </Typography>
          </Item>
          <Item key={11}>
            <Typography fontWeight="fontWeightMedium">   {t('connectorId')}</Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.connectorId?.split("_")?.pop()}
            </Typography>
          </Item>
          <Item key={1}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLLocation')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.locationId}:{chargingStation?.locationName}
            </Typography>
          </Item>
          <Item key={2}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLAddress')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.address?.careOf
                ? 'c/o ' + chargingStation?.address?.careOf
                : ''}
              {chargingStation?.address?.careOf ? <br /> : ''}
              {chargingStation?.address?.street} <br />
              {chargingStation?.address?.zipCode}{' '}
              {chargingStation?.address?.city}
            </Typography>
          </Item>
          <Item key={3}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLParkingLot')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.address?.parkingLot}
            </Typography>
          </Item>
          <Item key={4}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLModelOfCharginStation')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.wallboxModel}
            </Typography>
          </Item>
          <Item key={5}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLInOperationSince')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.onlineSince
                ? formatDate(chargingStation?.onlineSince, GERMAN_DATE_FORMAT)
                : '-'}
            </Typography>
          </Item>
        </Typography>
      </CardContent>
    </View>
  );
}