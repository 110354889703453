import { useAuth, useUser } from '../../../services/contexts';
import React, { useEffect } from 'react';
import { backendForFrontend } from '../../../services/utils/axios';
import { useCallback } from 'react';
import { Item, Spacer, View } from '../../UserDetail/styles';
import { CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
function ConfirmAddressUpdate({
  payload,
  submit,
  setError,
  setSubmit,
  openFeedback,
}) {
  const { t } = useTranslation();
  const { user } = useUser();
  const CUSTOMER_ID = user?.customer_id;
  const CUSTOMER_TYPE = user?.customer_type;
  const { token } = useAuth();
  useEffect(() => {
    if (submit > 0) updateOperator();
    setSubmit(0);
  }, [submit]);

  const updateOperator = async () => {
    try {
      await backendForFrontend.put(
        '/cpos/update-operator-information',
        {
          ...payload,
          workFlow: 'AddressInfo'
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      openFeedback();
    } catch (error) {
      setError(true);
    }
  };
  const updatedPayload = {
    customerId: CUSTOMER_ID,
    customerType: CUSTOMER_TYPE,
    cpoName: payload?.cpoName || '',
    cpoAdress: {
      careOf: payload?.cpoAddress.careOf || '',
      city: payload?.cpoAddress.city || '',
      street: payload?.cpoAddress.street || '',
      zipCode: payload?.cpoAddress.zipCode || '',
    }
  };

  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'customerId':
          return t('LBLOpId');
        case 'customerType':
          return t('LBLCustType');
        case 'cpoName':
          return 'Name';
        case 'cpoAdress':
          return t('LBLAddress');
        default:
          return key;
      }
    },
    [payload]
  );

  return (
    <>
      <View>
        <CardContent>
          <Spacer mb={4} />
          <Typography variant="body2" component="div" gutterBottom>
            {updatedPayload && (
              <>
                {Object.entries(updatedPayload).map(([key, value]) => {
                  return (
                    <>
                      {renderKey(key) && (
                        <>
                          {key === 'customerId' || key === 'customerType' ? (
                            <div></div>
                          ) : (
                            <Item key={key}>
                              <Typography fontWeight="fontWeightMedium">
                                {renderKey(key)}
                              </Typography>
                              {key === 'cpoAdress' ? (
                                <Typography
                                  fontWeight="fontWeightRegular"
                                  align="right"
                                >
                                  {payload?.cpoAddress?.careOf
                                    ? 'c/o ' + payload?.cpoAddress?.careOf
                                    : ''}
                                  {payload?.cpoAddress?.careOf ? <br /> : ''}
                                  {payload?.cpoAddress?.street} <br />
                                  {payload?.cpoAddress?.zipCode}{' '}
                                  {payload?.cpoAddress?.city}
                                </Typography>
                              ) : (
                                <Typography
                                  fontWeight="fontWeightRegular"
                                  align="right"
                                >
                                  {['string', 'number'].includes(typeof value)
                                    ? value
                                    : value?.join(', ') || null}
                                </Typography>
                              )}
                            </Item>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Typography>
        </CardContent>
      </View>
    </>
  );
}

export default ConfirmAddressUpdate;