import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import api from '../../Middleware/api/api';
import { useAuth } from '../../services/contexts';
import { useUser } from '../../services/contexts/UserContext';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import {
  WarningRounded,
  Add as AddIcon,
  ArrowForward,
} from '@mui/icons-material';

import {
  Divider as MuiDivider,
  Grid,
  Button,
  TableCell,
  Typography,
  Pagination,
} from '@mui/material';
import { spacing } from '@mui/system';
import OperatorForm from './components/OperatorForm';
import OperatorErstellung from './components/OperatorErstellung';
import StepperPopup from '../../components/StepperPopup';
import OperatorConfirmation from './components/OperatorConfirmation';
import Feedback from '../../components/Feedback';
import LogoLoader from '../../components/LogoLoader';
import DataTable from '../../components/DataTable';
import { PAGE_SIZE, MIN_PAGE_SIZE } from '../../services/utils/units-constants';
const Divider = styled(MuiDivider)(spacing);
let rows = [];
function EnhancedTable({ apiCallCount, handleReset, token, user  }) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [operators, setOperators] = useState([]);
  useEffect(async () => {
    if (apiCallCount > 0) {
    getOperator(pageSize, currentPage, apiCallCount);
    }
  }, [apiCallCount, currentPage, pageSize]);

  const getOperator = (seletedPageSize, currentPageVal, apiCallCount) => {
    setPageSize(seletedPageSize);
    setCurrentPage(currentPageVal);
    handleReset(0);
      try {
        setOperators([]);
        api.fetchOperators(token, currentPageVal, seletedPageSize).then(
          (response) => {
           
            if (response.data.data == null) {
              setOperators([]);
            } else {
              setTotalRecords(response?.data?.data?.totalNumberOfRecords);
              setOperators(response.data.data.response);
              
            }
          },
          () => {
            // handleReset();
            setOperators([]);
          }
        );
      } catch (exception) {
        setOperators([]);
        // handleReset();
      }
    
  };

  rows = operators.map((operator) => ({
    cpoId: operator.cpoId,
    cpoName: operator?.cpoName,
    nrOfLocations: operator?.nrOfLocations,
    nrOfEndCustomers: operator?.nrOfEndCustomers,
  }));

  const columns = useMemo(() => [
    {
      name: 'cpoId',
      label: t('LBLOpId'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value;
        },
        customHeadRender: () => (
          <TableCell
            align="left"
            variant="head"
            sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
          >
            {t('LBLOpId')}
          </TableCell>
        ),
      },
    },
    {
      name: 'cpoName',
      label: 'Name',
      options: {
        filter: true,
        display: 'true',
        filterOptions: {
          fullWidth: true,
        },
        customBodyRender: (value) => {
          return value;
        },
      },
    },

    {
      name: 'nrOfLocations',
      label: t('LBLNumLocations'),
      options: {
        filter: false,
        display: 'true',
        filterOptions: {
          fullWidth: true,
        },
        customHeadRender: () => (
          <TableCell
            align="right"
            variant="head"
            sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
          >
            {t('LBLNumLocations')}
          </TableCell>
        ),
        customBodyRender: (value) => (
          <Typography align="right">{value}</Typography>
        ),
      },
    },

    {
      name: 'nrOfEndCustomers',
      label: t('LBLNumUsers'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="right">{value} </Typography>;
        },
        customHeadRender: () => (
          <TableCell
            key={4}
            align="right"
            color="black"
            variant="head"
            sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
          >
            {t('LBLNumUsers')}
          </TableCell>
        ),
      },
    },

    {
      name: 'cpoId',
      label: '',
      options: {
        sort: false,
        filter: false,
        customHeadRender: () => (
          <TableCell
            key={7}
            align="right"
            variant="head"
            sx={{
              width: '200px',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1,
            }}
          >
            <span></span>
          </TableCell>
        ),
        customBodyRender: (value) => {
          return (
            <Button
              component={NavLink}
              to={`/betreiber/${value}/`}
              variant="text"
              align="right"
              style={{
                align: 'right',
                textAlign: 'right',
                marginLeft: '100px',
              }}
            >
              Details
            </Button>
          );
        },
      },
    },
  ]);
  return (
    <>
      <DataTable
        columns={columns}
        data={rows}
        getData={(seletedPageSize, currentPageVal) => {
          handleReset(1);
          setCurrentPage(currentPageVal);
          setPageSize(seletedPageSize);
        }}
        options={{
          textLabels: {
            body: {
              noMatch: t('LBLNoLocations'),
            },
          },
          currentPage: currentPage,
          totalRecords: totalRecords,
          filter: false,
        }}
      ></DataTable>
    </>
  );
}
function Operators() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitOperator, setSubmitOperator] = useState(0);

  const [submitOperatorErst, setSubmitOperatorErst] = useState(0);
  const [confirmOperator, setConfirmOperator] = useState(false);
  const [operatorResponse, setOperatorResponse] = useState(null);
  const [operatorRequestError, setOperatorRequestError] = useState(null);
  const [feedBackProps, setFeedBackProps] = useState({});
  // declaring states for the betreiber anlegen form
  const [operatorPayload, setOperatorPayload] = useState({});
  const [operatorPayloadErst, setOperatorPayloadErst] = useState({});
  const [canNextOperator, setCanNextOperator] = useState(false);
  const [canNextOperatorErst, setCanNextOperatorErst] = useState(false);
  // manage betreiber anlegen button
  const [showBetreiberButton, setShowBetreiberButton] = useState(true);
  function conditionToShowBetreiberButton(payload){
    if (
      (payload?.cpo_id?.length > 0 && payload?.customer_type === 'Operator') ||
      payload?.resource_access?.admin_oli_move?.roles.some(
        (role) => role === 'oli_move_admin'
      )
    ) {
      return false;
    }
    return true;
  }
  //keycloak configs
  useEffect(() => {
    let inputToken = localStorage.getItem('accessToken');
    const payload = jwtDecode(inputToken);
    setShowBetreiberButton(conditionToShowBetreiberButton(payload));
  }, [showBetreiberButton]);
  //standort states
  const { token } = useAuth();
  const { user } = useUser();
  const [accessTokenValue, setAccessTokenValue] = useState(token);

  const [apiCallCount, setApiCallCount] = useState(1);
  useEffect(() => {
    if (token && user) {
      // setApiCallCount(1);
    }
  }, [
    token,
    operatorResponse,
    operatorRequestError,
    showBetreiberButton,
    user,
    
  ]);
  useEffect(() => {
    setLoading(false);
    if (operatorResponse) {
      setFeedBackProps({
        title: <b> {t('newOpCreated')}</b>,
        message: (
          <Typography>
            {t('thankforInput')} <br />
            {t('LBOPID')} {operatorResponse?.data?.data} {t('Created')} <br />
            {t('teamwillbeintouch')}
          </Typography>
        ),
        open: true,
        handleClose: () => {
          setFeedBackProps({ ...feedBackProps, open: false });
          setOperatorResponse(null);
          setConfirmOperator(false);
          setApiCallCount(2);
        },
      });
    }
    if (operatorRequestError) {
      setFeedBackProps({
        title: <b>{t('mistake')} </b>,
        message: (
          <>
            <Grid container direction="row" alignItems="center">
              <Grid container direction="row" alignItems="center">
                {
                  <WarningRounded
                    color="warning"
                    sx={{
                      display: 'flex',
                      mx: 'auto',
                      fontSize: 'xxx-large',
                    }}
                  />
                }
              </Grid>
            </Grid>
            <Typography>{t('LBLSomethingwentWrong')}</Typography>
          </>
        ),
        open: true,
        handleClose: () => {
          setFeedBackProps({ ...feedBackProps, open: false });
          setOperatorRequestError(null);
          setConfirmOperator(false);
          setApiCallCount(2);
        },
      });
    }
  }, [operatorResponse, operatorRequestError]);
  const handleSuccess = (input, operatorResponseValue) => {
    setAccessTokenValue(input);
    const payload = jwtDecode(input);
    setShowBetreiberButton(conditionToShowBetreiberButton(payload));
    setOperatorResponse(operatorResponseValue);
  };
  const handleSetOperatorPayloadErst = (input) => {
    setOperatorPayloadErst(input);
  };

  const handleResetcount = (input) => {
setApiCallCount(input)
  };

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <LogoLoader loading={loading} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('LBLOpMgmt')}
          </Typography>
        </Grid>
        <Grid item mb={6}>
          <div>
            {showBetreiberButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <AddIcon />
                {t('LBLCreateOp')}
              </Button>
            )}

            <StepperPopup
              isOpen={open}
              title={t('LBLCreateOp')}
              setIsOpen={setOpen}
              steps={[
                {
                  label: t('enterOpinfo'),
                  component: (
                    <OperatorForm
                      submit={submitOperator}
                      setPayload={setOperatorPayload}
                      setCanNext={setCanNextOperator}
                      setSubmit={setSubmitOperator}
                    />
                  ),
                  nextAction: {
                    label: t('LBLFurther'),
                    icon: <ArrowForward />,
                    action: () => {
                      setSubmitOperator((prev) => prev + 1);
                    },
                    canNext: canNextOperator,
                  },
                },
                {
                  label: t('infoOnInvoiceTemp'),
                  component: (
                    <OperatorErstellung
                      submit={submitOperatorErst}
                      setPayload={handleSetOperatorPayloadErst}
                      setCanNext={setCanNextOperatorErst}
                      setSubmit={setSubmitOperatorErst}
                    />
                  ),
                  nextAction: {
                    label: t('LBLFurther'),
                    icon: <ArrowForward />,
                    action: () => {
                      setSubmitOperatorErst((prev) => prev + 1);
                    },
                    canNext: canNextOperatorErst,
                  },
                },
                {
                  label: t('confirmInfo'),
                  component: (
                    <OperatorConfirmation
                      payload={{
                        customerId: operatorPayload?.customerId,
                        customerType: operatorPayload?.customerType,
                        cpoName: operatorPayload?.cpoName,
                        cpoAddress: operatorPayload?.cpoAddress,
                        billingType: operatorPayloadErst?.billingType,
                        isChargingHistoryIncluded:
                          operatorPayloadErst?.isChargingHistoryIncluded,
                        contactPersonDetails:
                          operatorPayloadErst?.contactPersonDetails,
                        cpoPaymentDetails:
                          operatorPayloadErst?.cpoPaymentDetails,
                        billingTemplateHeader:
                          operatorPayloadErst?.billingTemplateHeader,
                        billingTemplateFooter:
                          operatorPayloadErst?.billingTemplateFooter,
                      }}
                      submit={confirmOperator}
                      setSuccess={handleSuccess}
                      setError={setOperatorRequestError}
                    />
                  ),
                  nextAction: {
                    label: t('LBLSave'),
                    action: () => {
                      setConfirmOperator(true);
                      setOpen(false);
                      setLoading(true);
                    },
                  },
                },
              ]}
            ></StepperPopup>
            <Feedback
              open={feedBackProps.open}
              message={feedBackProps.message}
              title={feedBackProps.title}
              handleClose={feedBackProps.handleClose}
            ></Feedback>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <EnhancedTable apiCallCount={apiCallCount} handleReset={handleResetcount} token={token} user={user} />
    </React.Fragment>
  );
}

export default Operators;