import { useAuth } from '../../services/contexts';
import React, { useEffect, useCallback } from 'react';
import { backendForFrontend } from '../../services/utils/axios';
import { Item, Spacer, View } from '../UserDetail/styles';
import { CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
function ConfirmLocatoinInformation({
  payload,
  submit,
  setError,
  setSubmit,
  openFeedback,
}) {
  const { t } = useTranslation();
  const { token } = useAuth();

  useEffect(() => {
    if (submit > 0) updateUser();
    setSubmit(0);
  }, [submit]);

  const updateUser = async () => {
    try {
      const newPayload = {
        locationId: payload?.locationId || '',
        locationName: payload?.locationName || '',
        locationAddress: {
          street: payload?.locationAddress.street || '',
          city: payload?.locationAddress.city || '',
          zipCode: payload?.locationAddress.zipCode || '',
          countryCode: 'DE',
          careOf: payload?.locationAddress.careOf || '',
        },
      };

      await backendForFrontend
        .put('/locations/update-location', newPayload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
        });
      openFeedback();
    } catch (error) {
      console.log('eroor ' + error);
      setError(true);
    }
  };
  const updatedPayload = {
    locationId: payload?.locationId || '',
    locationName: payload?.locationName || '',
    locationAdress: {
      careOf: payload?.locationAddress.careOf || '',
      city: payload?.locationAddress.city || '',
      street: payload?.locationAddress.street || '',
      zipCode: payload?.locationAddress.zipCode || '',
    },
    cpoId: payload?.cpoId || '',
    cpoName: payload?.cpoName || '',
  };
  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'locationId':
          return t('LocationId');
        case 'locationName':
          return t('LBLLocationName');
        case 'locationAdress':
          return t('LBLAddress');
        case 'cpoId':
          return t('LBLOpId');
        case 'cpoName':
          return t('opName');
        default:
          return key;
      }
    },
    [payload]
  );

  return (
    <>
      <View>
        <CardContent>
          <Spacer mb={4} />
          <Typography variant="body2" component="div" gutterBottom>
            {updatedPayload && (
              <>
                {Object.entries(updatedPayload).map(([key, value]) => {
                  return (
                    <>
                      {renderKey(key) && (
                        <>
                          <Item key={key}>
                            <Typography fontWeight="fontWeightMedium">
                              {renderKey(key)}
                            </Typography>
                            {key === 'locationAdress' ? (
                              <Typography
                                fontWeight="fontWeightRegular"
                                align="right"
                              >
                                {payload?.locationAddress?.careOf
                                  ? 'c/o ' + payload?.locationAddress?.careOf
                                  : ''}
                                {payload?.locationAddress?.careOf ? <br /> : ''}
                                {payload?.locationAddress?.street} <br />
                                {payload?.locationAddress?.zipCode}{' '}
                                {payload?.locationAddress?.city}
                              </Typography>
                            ) : (
                              <Typography
                                fontWeight="fontWeightRegular"
                                align="right"
                              >
                                {['string', 'number'].includes(typeof value)
                                  ? value
                                  : value?.join(', ') || null}
                              </Typography>
                            )}
                          </Item>
                        </>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Typography>
        </CardContent>
      </View>
    </>
  );
}

export default ConfirmLocatoinInformation;